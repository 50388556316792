import { theme } from '../../theme';
import type { QrCodeStyling } from './ChkQrCode.types';

export const DEFAULT_QR_CODE_CONFIG = {
  width: 120,
  height: 120,
  dotsOptions: {
    color: theme.palette.navy500,
    type: 'square',
  },
} as QrCodeStyling;
