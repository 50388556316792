import { useAsync, useAsyncAbortable } from '@react-hookz/web';

import { asyncFetch } from './useApi.utils';

type ArgsWithQuerystring = {
  querystring?: URLSearchParams;
} & Record<string, unknown>;

/**
 * Hook to (attempt to) uniform API usage
 * @param url the API url to be used
 * @param options any option `fetch` can handle
 * @returns
 */

const useApi = <T>(url: string, options?: RequestInit, apiBaseUrl?: string) => {
  const [state, actions, meta] = useAsync<T | null>((args) => {
    const { querystring, ...restArgs } = (args as ArgsWithQuerystring) || {};
    const queryParams = querystring ? `?${querystring.toString()}` : '';

    return asyncFetch(
      `${url}${queryParams}`,
      {
        ...options,
        ...Object.assign({}, restArgs),
      },
      apiBaseUrl
    );
  });

  const { execute, ...restActions } = actions;

  return {
    ...state,
    ...meta,
    ...restActions,
    isLoading: state.status === 'loading',
    executeRequest: execute,
  };
};

const useApiAbortable = <T>(
  url: string,
  options?: RequestInit,
  apiBaseUrl?: string
) => {
  const [state, actions, meta] = useAsyncAbortable<T | null>((signal, args) => {
    const { querystring, ...restArgs } = (args as ArgsWithQuerystring) || {};
    const queryParams = querystring ? `?${querystring.toString()}` : '';

    return asyncFetch(
      `${url}${queryParams}`,
      {
        ...options,
        signal,
        ...Object.assign({}, restArgs),
      },
      apiBaseUrl
    );
  });

  const { execute, abort, ...restActions } = actions;

  return {
    ...state,
    ...meta,
    ...restActions,
    isLoading: state.status === 'loading',
    executeRequest: execute,
    abort,
  };
};

export { useApi, useApiAbortable };
