import { createContext, useContext, useMemo } from 'react';
import type { ReactNode } from 'react';

import type { JwtContextData, JwtContextProviderProps } from './jwt.types';
import { getJwtPayload } from './jwt.utils';

/**
 * This factory returns `JwtContextProvider`, `useJwtContext` and `withJwtContextProvider`;
 * Usage:
 * ````
 *   import { jwtContextFactory } from '@apps/checkout-contexts';
 *   const { JwtContextProvider, useJwtContext, withJwtContextProvider } = jwtContextFactory<YOUR_JWT_HERE>();
 *
 *  export { JwtContextProvider, useJwtContext, withJwtContextProvider };
 * ````
 */
const jwtContextFactory = <T,>() => {
  const JwtContext = createContext<JwtContextData<T> | null>(null);

  const useJwtContext = () => {
    const context = useContext(JwtContext);

    if (!context) {
      throw new Error(
        'useJwtContext must be used within a <JwtContextProvider>'
      );
    }

    return context;
  };

  const JwtContextProvider = ({
    children,
    tokenKey,
  }: JwtContextProviderProps) => {
    const { payload, token, tokenError } = useMemo(
      () => getJwtPayload<T>(tokenKey),
      [tokenKey]
    );

    return (
      <JwtContext.Provider
        value={{
          payload,
          token,
          tokenError,
        }}
      >
        {children}
      </JwtContext.Provider>
    );
  };

  /**
   * helper to be used when wrapping a component with JwtContextProvider is needed, out of JSX structures eg. in functions
   */
  const withJwtContextProvider = ({ children }: { children: ReactNode }) => (
    <JwtContextProvider>{children}</JwtContextProvider>
  );

  return {
    JwtContextProvider,
    useJwtContext,
    withJwtContextProvider,
  };
};

export { jwtContextFactory };
