import type { Shadows } from '@mui/material/styles';

export const shadows: Shadows = [
  'none',
  '0 2px 10px 0 rgba(0, 0, 0, 0.05)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
  '0 4px 34px 0 rgba(0, 0, 0, 0.1)',
];
