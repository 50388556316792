import { jwtDecode } from 'jwt-decode';

import { setSentryContext } from '@apps/checkout-utils';

import type { JwtContextData } from './jwt.types';

export const mockJwtQueryParam = (token: string, key = 'auth') => {
  global.window = Object.create(window);
  Object.defineProperty(window, 'location', {
    value: {
      search: `?${key}=${token}`,
    },
  });
};

export function getJwtPayload<T>(tokenKey = 'auth'): JwtContextData<T> {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get(tokenKey);
  let tokenError: Error | null = null;
  let payload: T | null = null;

  try {
    if (!token) {
      throw new Error('Invalid token');
    }

    payload = jwtDecode<T>(token);
  } catch (error) {
    if (error instanceof Error) {
      tokenError = error;
    }
  }

  setSentryContext({
    key: 'jwt',
    val: payload,
  });

  return {
    payload,
    token,
    tokenError,
  };
}
