import { Alert, styled } from '@mui/material';

import type { ChkAlertProps } from './ChkAlert.types';

export const ChkAlert = ({ big = false, children, ...rest }: ChkAlertProps) => {
  return (
    <StyledAlert big={big} {...rest}>
      {children}
    </StyledAlert>
  );
};

const StyledAlert = styled(Alert, {
  shouldForwardProp: (prop) => prop !== 'big',
})<{ big: ChkAlertProps['big'] }>(({ big, theme }) => ({
  display: 'flex',
  flexDirection: big ? 'column' : 'row',
  alignItems: 'center',
  padding: big ? `${theme.spacing(6)} ${theme.spacing(4)}` : theme.spacing(5),
  textAlign: big ? 'center' : 'left',
  border: theme.borders.borderWidth1,
  borderRadius: theme.borders.borderRadius3,
  color: theme.tokens.alertTextDefault,

  '&.MuiAlert-standardInfo': {
    backgroundColor: theme.tokens.alertInfoBackground,
    borderColor: theme.tokens.alertInfoBorder,
  },

  '&.MuiAlert-standardError': {
    backgroundColor: theme.tokens.alertWarningBackground,
    borderColor: theme.tokens.alertWarningBorder,
  },

  '.MuiAlert-icon, .MuiAlert-message': {
    padding: 0,
  },

  '.MuiAlert-icon': {
    alignSelf: big ? 'initial' : 'flex-start',
  },
}));
