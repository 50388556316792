import { Box, styled } from '@mui/material';

import { ChkBankTile } from '@apps/checkout-components';

import { ErrorScreen } from '../ErrorScreen/ErrorScreen';
import type { BanksListProps } from './BanksList.types';

export const BanksList = ({
  banks,
  onBankTileClick,
  disableTiles,
  isError,
}: BanksListProps) => {
  if (isError) return <ErrorScreen />;

  return (
    <StyledBanksGrid>
      {banks?.map(({ id, name, assets, active, ...rest }) => (
        <ChkBankTile
          key={id}
          bankName={name}
          iconUrl={assets.logo}
          onClick={() => onBankTileClick({ id, name, assets, active, ...rest })}
          disabled={disableTiles || !active}
        />
      ))}
    </StyledBanksGrid>
  );
};

const StyledBanksGrid = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  justifyItems: 'center',
  rowGap: theme.spacing(3),
  [theme.breakpoints.up('xs')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    columnGap: theme.spacing(5),
  },
}));
