import { Box, styled, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { trackEvent } from '@apps/checkout-utils';

import {
  DRAWER_DESKTOP_VIEW_TEST_ID,
  QR_TEXT_TEST_ID,
  QR_TITLE_TEST_ID,
  UK_EVENTS,
} from '../../../../global.constants';
import { useStatusContext } from '../../../../hooks/useStatusContext/useStatusContext';
import { addParamToUrl } from '../../../../utils/index.utils';
import { TermsAndConditions } from '../../../TermsAndConditions/TermsAndConditions';
import { DrawerContinueOnDesktop } from '../DrawerContinueOnDesktop/DrawerContinueOnDesktop';
import { DrawerQrCode } from '../DrawerQrCode/DrawerQrCode';
import {
  DRAWER_DESKTOP_VIEW,
  QR_URL_PARAMETER,
} from './DrawerDesktopView.constants';
import type { DrawerDesktopViewProps } from './DrawerDesktopView.types';

export const DrawerDesktopView = ({
  redirectUrl,
  iconUrl,
}: DrawerDesktopViewProps) => {
  const { statusPayload } = useStatusContext();
  const redirectUrlWithQrParam = useMemo(
    () =>
      addParamToUrl({
        passedUrl: redirectUrl,
        paramName: QR_URL_PARAMETER,
        paramValue: '',
      }),
    [redirectUrl]
  );

  useEffect(() => {
    trackEvent(UK_EVENTS.QR_CODE_DISPLAYED, {
      licence: statusPayload?.details.licence,
      providerName: statusPayload?.details.providerName,
      redirectUrl: statusPayload?.details.redirectUrl,
    });
  }, [
    statusPayload?.details.redirectUrl,
    statusPayload?.details.licence,
    statusPayload?.details.providerName,
  ]);

  return (
    <Box data-testid={DRAWER_DESKTOP_VIEW_TEST_ID}>
      <StyledBox>
        <Box>
          <StyledHeading variant="h3" data-testid={QR_TITLE_TEST_ID}>
            {DRAWER_DESKTOP_VIEW.title}
          </StyledHeading>
          <StyledBody variant="body2" data-testid={QR_TEXT_TEST_ID}>
            {DRAWER_DESKTOP_VIEW.subtitle}
          </StyledBody>
          <TermsAndConditions
            provider={statusPayload?.details.providerName ?? ''}
          />
        </Box>
        <DrawerQrCode url={redirectUrlWithQrParam} iconUrl={iconUrl} />
      </StyledBox>
      <DrawerContinueOnDesktop redirectUrl={redirectUrl} />
    </Box>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(5)} ${theme.spacing(8)}`,
  gap: theme.spacing(5),

  '& > div > div': {
    textAlign: 'left',
  },
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  lineHeight: 1.3,
  color: theme.tokens.bottomSheetTextCardTitle,
  marginBottom: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
}));

const StyledBody = styled(Typography)(({ theme }) => ({
  color: theme.tokens.bottomSheetTextCardDescription,
  letterSpacing: '-0.2px',
  marginBottom: theme.spacing(6),
}));
