import type { ChkIconProps } from './ChkIcon.types';
import { ChkSvgWrapper } from './ChkIcon.utils';

export const ChkIconVoltTile = (iconProps: ChkIconProps) => (
  <ChkSvgWrapper {...iconProps}>
    <path
      d="M56 12C56 5.37258 50.6274 0 44 0H12C5.37259 0 0 5.37258 0 12V44C0 50.6274 5.37258 56 12 56H44C50.6274 56 56 50.6274 56 44V12Z"
      fill="#192253"
    />
    <path
      d="M36.9793 24.1621L27.9853 42.8202L18.9951 24.1621H22.9775L27.9853 34.8049L33.0001 24.1621H36.9793Z"
      fill="#5BE3A4"
    />
    <path d="M36.9811 15.416H19.002V18.682H36.9811V15.416Z" fill="#5BE3A4" />
  </ChkSvgWrapper>
);
