import { ChkStatusScreen } from '../';
import { DEFAULT_TEXT, DEFAULT_TITLE } from './ChkGenericError.constants';
import type { ChkGenericErrorProps } from './ChkGenericError.types';

export const ChkGenericError = ({
  children,
  title,
  text,
  ...restProps
}: ChkGenericErrorProps) => {
  const screenTitle = title || DEFAULT_TITLE;
  const screenText = text || DEFAULT_TEXT;

  return (
    <ChkStatusScreen
      animation="failed"
      loop={false}
      title={screenTitle}
      text={screenText}
      {...restProps}
    >
      {children}
    </ChkStatusScreen>
  );
};
