import { palette } from './palette';

export const tokens = {
  alertInfoBackground: palette.blue10,
  alertInfoBorder: palette.blue50,
  alertInfoIcon: palette.blue500,
  alertTextDefault: palette.steel500,
  alertWarningBackground: palette.red10,
  alertWarningBorder: palette.red50,
  alertWarningIcon: palette.red500,
  bottomSheetFeedbackBackground: palette.white,
  bottomSheetFeedbackKeyline: palette.blue50,
  bottomSheetHandoffBackground: palette.navy25,
  bottomSheetHandoffBackgroundHighlight: palette.white,
  bottomSheetHandoffKeyline: palette.blue50,
  bottomSheetHelpBackground: palette.white,
  bottomSheetHelpBorder: palette.blue50,
  bottomSheetTextCardDescription: palette.steel300,
  bottomSheetTextCardTitle: palette.steel500,
  bottomSheetTextSubheader: palette.steel300,
  bottomSheetBackgroundLoader: palette.navy50,
  bottomSheetBodyLink: palette.blue500,
  buttonDefaultBackgroundDefault: palette.navy500,
  buttonDefaultBackgroundDisabled: palette.navy50,
  buttonDefaultBackgroundHover: palette.navy400,
  buttonDefaultBackgroundLoading: palette.navy50,
  buttonDefaultBackgroundPressed: palette.navy600,
  buttonDefaultIconDefault: palette.white,
  buttonDefaultIconDisabled: palette.navy200,
  buttonDefaultTextDefault: palette.white,
  buttonDefaultTextDisabled: palette.navy200,
  buttonDefaultTextLoading: palette.navy200,
  buttonSecondaryBackgroundDefault: palette.white,
  buttonSecondaryBackgroundDisabled: palette.navy50,
  buttonSecondaryBackgroundHover: palette.navy50,
  buttonSecondaryBackgroundLoading: palette.navy50,
  buttonSecondaryBackgroundPressed: palette.navy50,
  buttonSecondaryBorderDefault: palette.navy75,
  buttonSecondaryBorderHover: palette.navy300,
  buttonSecondaryIconDefault: palette.navy500,
  buttonSecondaryIconDisabled: palette.navy200,
  buttonSecondaryTextDefault: palette.navy500,
  buttonSecondaryTextDisabled: palette.navy200,
  buttonSecondaryTextLoading: palette.navy200,
  buttonTertiaryBackgroundDefault: palette.navy50,
  buttonTertiaryBackgroundDisabled: palette.navy100,
  buttonTertiaryBackgroundHover: palette.navy50,
  buttonTertiaryBackgroundLoading: palette.navy100,
  buttonTertiaryBackgroundPressed: palette.navy100,
  buttonTertiaryBorderDefault: palette.navy50,
  buttonTertiaryBorderHover: palette.navy50,
  buttonTertiaryBorderPressed: palette.navy50,
  buttonTertiaryTextDefault: palette.steel500,
  buttonTertiaryTextDisabled: palette.navy200,
  cardsOptionsBorderActive: palette.blue500,
  cardsOptionsBorderDefault: palette.navy50,
  cardsOptionsBorderHover: palette.navy300,
  cardsOptionsBorderSelected: palette.steel500,
  cardsOptionsTextSubtitle: palette.steel300,
  cardsOptionsTextTitle: palette.steel500,
  controlsBorderActive: palette.blue500,
  controlsBorderDefault: palette.navy75,
  controlsBorderDisabled: palette.navy50,
  controlsInnerActive: palette.steel500,
  controlsInnerHover: palette.steel300,
  controlsTextActive: palette.steel500,
  controlsTextDefault: palette.steel300,
  controlsTextDisabled: palette.steel200,
  coreBackgroundsControllers: palette.navy25,
  coreBackgroundsLightPrimary: palette.blue50,
  coreBackgroundsLightSecondary: palette.navy50,
  coreBackgroundsPrimary: palette.steel500,
  coreBackgroundsPrimaryAlt: palette.navy50,
  coreBackgroundsPrimaryHover: palette.steel400,
  coreBackgroundsPrimaryPressed: palette.steel600,
  coreBackgroundsSecondary: palette.white,
  coreBackgroundsSecondaryAlt: palette.navy50,
  coreBackgroundsSecondaryHover: palette.navy50,
  coreBackgroundsSecondaryPressed: palette.navy50,
  coreBackgroundsTertiary: palette.navy50,
  coreBackgroundsTertiaryAlt: palette.navy100,
  coreBorderActive: palette.blue500,
  coreBorderDefault: palette.navy75,
  coreBorderDisabled: palette.navy50,
  coreBorderError: palette.red500,
  coreBorderHover: palette.navy300,
  coreBorderLight: palette.blue50,
  coreBorderTertiary: palette.navy25,
  coreBorderTertiaryDark: palette.navy50,
  coreBorderTertiaryLight: palette.navy50,
  coreMainActive: palette.blue500,
  coreMainDisabled: palette.navy50,
  coreMainError: palette.red500,
  coreMainPrimary: palette.steel500,
  coreMainSecondary: palette.steel300,
  coreMainTertiary: palette.steel200,
  coreMainWhite: palette.white,
  coreMainLinkDefault: palette.blue500,
  coreMainLinkDisabled: palette.blue200,
  coreMainLinkHover: palette.blue400,
  coreMainLinkPressed: palette.blue600,
  coreStatusInfoBackground: palette.blue10,
  coreStatusInfoIcon: palette.blue500,
  coreStatusInfoStroke: palette.blue50,
  coreStatusWarningBackground: palette.red10,
  coreStatusWarningIcon: palette.red500,
  coreStatusWarningStroke: palette.red50,
  iconsDefault: palette.steel500,
  iconsDisabled: palette.steel200,
  iconsOnboardingDefault: palette.navy75,
  iconsOnboardingHighlight: palette.navy50,
  inputsBackgroundDefault: palette.white,
  inputsBackgroundDisabled: palette.navy50,
  inputsBorderActive: palette.blue500,
  inputsBorderDefault: palette.navy75,
  inputsBorderDisabled: palette.navy50,
  inputsBorderError: palette.red500,
  inputsBorderFilled: palette.navy75,
  inputsBorderHover: palette.navy300,
  inputsHelpHelpDefault: palette.steel300,
  inputsHelpHelpDisabled: palette.steel200,
  inputsHelpHelpError: palette.red500,
  inputsHelpHelpLink: palette.blue500,
  inputsLabelDefault: palette.steel500,
  inputsLabelDisabled: palette.steel200,
  inputsValueActive: palette.steel500,
  inputsValuePlaceholder: palette.steel200,
  searchColor: palette.white,
  searchBackgroundActive: palette.white,
  searchBackgroundDefault: palette.white,
  searchBackgroundDisabled: palette.navy50,
  searchBackgroundFilled: palette.navy10,
  searchBackgroundHover: palette.navy10,
  searchBackgroundResultsLabel: palette.steel500,
  searchBackgroundResultsLabelDisabled: palette.steel200,
  searchBackgroundResultsPressed: palette.navy25,
  searchBackgroundResultsTitle: palette.steel500,
  searchBorderActive: palette.blue500,
  searchBorderAndroid: palette.navy75,
  searchBorderDefault: palette.navy75,
  searchBorderFilled: palette.navy75,
  searchBorderHover: palette.navy300,
  searchBorderIOSActive: palette.blue500,
  searchBorderIOSDefault: palette.navy75,
  searchTextDisabled: palette.steel200,
  searchTextFilled: palette.steel500,
  searchTextHover: palette.steel300,
  searchTextLink: palette.blue500,
  searchTextPlaceholder: palette.steel200,
  segmentedControllerActive: palette.white,
  segmentedControllerBackground: palette.navy25,
  segmentedControllerBorder: palette.navy50,
  segmentedControllerText: palette.steel500,
  tagBackground: palette.blue50,
  tagValue: palette.blue500,
  textButtonDefault: palette.blue500,
  textButtonDisabled: palette.blue200,
  textButtonHover: palette.blue400,
  textButtonPressed: palette.blue600,
  toastBackground: palette.steel300,
  toastIcon: palette.white,
  toastText: palette.white,
  tooltipsBackground: palette.steel500,
  tooltipsText: palette.white,
};

type Palette = typeof palette;
type Color = Palette[keyof Palette];
type Tokens = { [T in keyof typeof tokens]: Color };

declare module '@mui/material/styles' {
  interface Theme {
    tokens: Tokens;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    tokens: Tokens;
  }
}
