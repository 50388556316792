import type { ChkIconProps } from './ChkIcon.types';
import { ChkSvgWrapper } from './ChkIcon.utils';

export const ChkIconMagnifyingGlass = (iconProps: ChkIconProps) => (
  <ChkSvgWrapper {...iconProps}>
    <path
      d="M21.4057 19.677L16.9903 15.2615C18.2494 13.8135 18.8971 11.9332 18.7969 10.0169C18.6967 8.10063 17.8564 6.29811 16.4531 4.98932C15.0498 3.68053 13.1932 2.96771 11.2746 3.00112C9.35599 3.03454 7.52531 3.81158 6.16844 5.16844C4.81158 6.52531 4.03454 8.35599 4.00112 10.2746C3.96771 12.1932 4.68053 14.0498 5.98932 15.4531C7.29811 16.8564 9.10063 17.6967 11.0169 17.7969C12.9332 17.8971 14.8135 17.2494 16.2615 15.9903L20.677 20.4057C20.7747 20.4968 20.904 20.5464 21.0376 20.544C21.1712 20.5417 21.2986 20.4876 21.3931 20.3931C21.4876 20.2986 21.5417 20.1712 21.544 20.0376C21.5464 19.904 21.4968 19.7747 21.4057 19.677ZM5.05699 10.4164C5.05699 9.1586 5.42996 7.92908 6.12874 6.88329C6.82752 5.83749 7.82071 5.0224 8.98274 4.54107C10.1448 4.05974 11.4234 3.93381 12.657 4.17918C13.8906 4.42456 15.0237 5.03023 15.9131 5.91961C16.8025 6.80898 17.4082 7.94212 17.6535 9.17571C17.8989 10.4093 17.773 11.688 17.2917 12.85C16.8103 14.012 15.9952 15.0052 14.9494 15.704C13.9037 16.4028 12.6741 16.7757 11.4164 16.7757C9.73038 16.7737 8.11404 16.103 6.92187 14.9109C5.7297 13.7187 5.05904 12.1023 5.05699 10.4164Z"
      fill="currentColor"
    />
  </ChkSvgWrapper>
);
