import {
  CUSTOMER_NAME_AT_SUMMARY_PAGE_TEST_ID,
  PAYMENT_AMOUNT_AT_SUMMARY_PAGE_TEST_ID,
  REFERENCE_AT_SUMMARY_PAGE_TEST_ID,
} from '../../global.constants';
import type { SummaryItem } from './ChkSummary.types';

export const addTestId = (item: SummaryItem): SummaryItem => {
  const isCustomerItem = item.label === 'To';
  const isReferenceItem = item.label === 'Reference';
  const isTotalItem = item.label === 'Total';

  if (isCustomerItem) {
    item.dataTestId = CUSTOMER_NAME_AT_SUMMARY_PAGE_TEST_ID;
  } else if (isReferenceItem) {
    item.dataTestId = REFERENCE_AT_SUMMARY_PAGE_TEST_ID;
  } else if (isTotalItem) {
    item.dataTestId = PAYMENT_AMOUNT_AT_SUMMARY_PAGE_TEST_ID;
  }

  return item;
};
