import { styled } from '@mui/material';
import { forwardRef, useMemo } from 'react';

import { ChkDrawer } from '@apps/checkout-components';
import type { ChkDrawerProps } from '@apps/checkout-components';

import { useStatusContext } from '../../hooks/useStatusContext/useStatusContext';
import { useStatusPolling } from '../../hooks/useStatusPolling/useStatusPolling';
import { DrawerContent } from './DrawerContent/DrawerContent';
import { DrawerError } from './DrawerError/DrawerError';

export const SummaryDrawer = forwardRef<HTMLDivElement, ChkDrawerProps>(
  (_, ref) => {
    const { error } = useStatusContext();
    const { startStatusPolling } = useStatusPolling();

    const summaryDrawerContent = useMemo(
      () =>
        error ? (
          <DrawerError
            onRetry={startStatusPolling}
            errorMessage={error.message}
          />
        ) : (
          <DrawerContent />
        ),
      [error, startStatusPolling]
    );

    return (
      <StyledDrawer open hideBackdrop ref={ref}>
        {summaryDrawerContent}
      </StyledDrawer>
    );
  }
);

const StyledDrawer = styled(ChkDrawer)(({ theme }) => ({
  position: 'static',
  width: 'auto',
  margin: ['auto', `-${theme.spacing(5)}`, 0].join(' '),
  borderBottomLeftRadius: '8px',
  '& > .MuiDrawer-paper': {
    borderBottomLeftRadius: theme.borders.borderRadius0,
    borderBottomRightRadius: theme.borders.borderRadius0,
  },

  [theme.breakpoints.up('xs')]: {
    marginLeft: `-${theme.spacing(8)}`,
    marginRight: `-${theme.spacing(8)}`,
    '& > .MuiDrawer-paper': {
      borderBottomLeftRadius: theme.borders.borderRadius2,
      borderBottomRightRadius: theme.borders.borderRadius2,
    },
  },
}));
