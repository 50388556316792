import { useMediaQuery } from '@mui/material';

import { theme } from '@apps/checkout-components';

import type { UseBreakpointsReturnValue } from './useBreakpoints.types';

export const useBreakpoints = (): UseBreakpointsReturnValue => {
  const isLessThanXXS = useMediaQuery(theme.breakpoints.down('xxs'));

  return { isLessThanXXS };
};
