import { Player } from '@lottiefiles/react-lottie-player';

import { ANIMATION_MAP } from './ChkLottieAnimation.types';
import type { LottieAnimationProps } from './ChkLottieAnimation.types';

export const ChkLottieAnimation = ({
  src,
  children,
  loop = true,
}: LottieAnimationProps) => {
  const animationSrc = ANIMATION_MAP[src];

  return (
    <Player autoplay keepLastFrame loop={loop} src={animationSrc}>
      {children}
    </Player>
  );
};
