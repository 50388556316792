import mixPanel from 'mixpanel-browser';

import { MIXPANEL_TOKEN } from '@chk/env-variables';

import { captureException } from '../sentry/sentry';
import type {
  InitMixpanel,
  RegisterEventProperties,
  TrackEvent,
} from './mixpanel.types';

const DEFAULT_MIXPANEL_CONFIG = {
  api_host: 'https://mp.volt.io',
  batch_size: 1,
  batch_flush_interval_ms: 1000,
  batch_request_timeout_ms: 15000,
  secure_cookie: true,
  cookie_expiration: 1,
  disable_persistence: true,
  ignore_dnt: true,
};

export const initMixpanel: InitMixpanel = (customMixpanelConfig) => {
  const token = MIXPANEL_TOKEN;
  try {
    if (!token) {
      return;
    }
    const debugMode = token === 'DUMMY_TOKEN';

    mixPanel.init(token, {
      debug: debugMode,
      ...DEFAULT_MIXPANEL_CONFIG,
      ...customMixpanelConfig,
    });
  } catch (error) {
    if (error instanceof Error) {
      captureException(error);
    }
  }
};

export const trackEvent: TrackEvent = (name, properties, options) => {
  try {
    mixPanel.track(name, properties, options);
  } catch (error) {
    if (error instanceof Error) {
      captureException(error);
    }
  }
};

export const registerGlobalProperties: RegisterEventProperties = (
  properties
) => {
  try {
    mixPanel.register(properties);
  } catch (error) {
    if (error instanceof Error) {
      captureException(error);
    }
  }
};
