import { Box, styled, Typography, useTheme } from '@mui/material';

import { ChkLottieAnimation } from '../ChkLottieAnimation/ChkLottieAnimation';
import type { ChkStatusScreenProps } from './ChkStatusScreen.types';

const STATUS_SCREEN_TITLE_ID = 'status-screen-title';
const STATUS_SCREEN_TEXT_ID = 'status-screen-text';

export const ChkStatusScreen = ({
  title,
  text,
  animation,
  children,
  loop = true,
  ...rest
}: ChkStatusScreenProps) => {
  const { tokens, typography } = useTheme();

  return (
    <StyledStatusScreen {...rest}>
      <StyledAnimationWrapper aria-hidden="true">
        <ChkLottieAnimation loop={loop} src={animation} />
      </StyledAnimationWrapper>
      {(title || text) && (
        <StyledTextWrapper>
          <Typography
            variant="h2"
            color={tokens.coreMainPrimary}
            fontWeight={typography.fontWeightBold}
            data-testid={STATUS_SCREEN_TITLE_ID}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color={tokens.coreMainSecondary}
            letterSpacing={-0.3}
            data-testid={STATUS_SCREEN_TEXT_ID}
          >
            {text}
          </Typography>
        </StyledTextWrapper>
      )}
      {children}
    </StyledStatusScreen>
  );
};

const StyledStatusScreen = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '49.2rem',
  flex: '1 1 auto',
});

const StyledTextWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  marginTop: theme.spacing(7),
  textAlign: 'center',
  paddingLeft:'32px',
  paddingRight:'32px',
}));

const StyledAnimationWrapper = styled(Box)({
  maxWidth: '15rem',
});
