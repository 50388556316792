import { Paper, styled } from '@mui/material';
import { useState } from 'react';

import { FALLBACK_ICON_TEST_ID } from '../../global.constants';
import { ChkIconBankPlaceholder } from '../icons';
import type { ChkLogoWrapperProps } from './ChkLogoWrapper.types';

export const ChkLogoWrapper = ({
  altText = '',
  elevation = 0,
  iconUrl,
  ...rest
}: ChkLogoWrapperProps) => {
  const [loadingError, setLoadingError] = useState<boolean>(false);

  return (
    <StyledPaper
      iconUrl={iconUrl}
      loadingError={loadingError}
      elevation={elevation}
      {...rest}
    >
      {iconUrl && !loadingError ? (
        <img
          src={iconUrl}
          alt={altText}
          onError={() => setLoadingError(true)}
        />
      ) : (
        <ChkIconBankPlaceholder size="lg" data-testid={FALLBACK_ICON_TEST_ID} />
      )}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'iconUrl' && prop !== 'loadingError',
})<{ iconUrl: ChkLogoWrapperProps['iconUrl']; loadingError: boolean }>(
  ({ theme, iconUrl, loadingError }) => ({
    width: '5.6rem',
    aspectRatio: '1 / 1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.borders.borderRadius3,
    overflow: 'hidden',
    backgroundColor:
      iconUrl && !loadingError
        ? 'transparent'
        : theme.tokens.coreBackgroundsLightSecondary,

    img: {
      width: '100%',
    },
  })
);
