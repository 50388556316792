import type { ChkIconProps } from './ChkIcon.types';
import { ChkSvgWrapper } from './ChkIcon.utils';

export const ChkIconClose = (iconProps: ChkIconProps) => (
  <ChkSvgWrapper {...iconProps}>
    <g clipPath="url(#clip0_2292_75407)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0104 7.01017C18.2057 6.81491 18.2057 6.49833 18.0104 6.30307C17.8151 6.10781 17.4986 6.10781 17.3033 6.30307L11.9999 11.6065L6.69669 6.30332C6.50143 6.10806 6.18485 6.10806 5.98959 6.30332C5.79433 6.49858 5.79433 6.81517 5.98959 7.01043L11.2928 12.3136L5.98959 17.6168C5.79432 17.812 5.79432 18.1286 5.98959 18.3239C6.18485 18.5191 6.50143 18.5191 6.69669 18.3239L11.9999 13.0207L17.3033 18.3241C17.4986 18.5194 17.8151 18.5194 18.0104 18.3241C18.2057 18.1289 18.2057 17.8123 18.0104 17.617L12.707 12.3136L18.0104 7.01017Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2292_75407">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </ChkSvgWrapper>
);
