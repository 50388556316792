import type { ChkIconProps } from './ChkIcon.types';
import { ChkSvgWrapper } from './ChkIcon.utils';

export const ChkIconCartTile = (iconProps: ChkIconProps) => (
  <ChkSvgWrapper {...iconProps}>
    <path
      d="M56 12C56 5.37258 50.6274 0 44 0H12C5.37259 0 0 5.37258 0 12V44C0 50.6274 5.37258 56 12 56H44C50.6274 56 56 50.6274 56 44V12Z"
      fill="white"
    />
    <g clipPath="url(#clip0_9696_32896)">
      <path
        d="M20.1008 29.9315H35.2533C35.7056 29.9315 36.1434 29.7727 36.4906 29.4829C36.8377 29.1931 37.0722 28.7906 37.153 28.3457L38.6206 20.2764H18.3447"
        stroke="#192253"
        strokeWidth="1.93103"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5891 34.7586C22.1369 34.7585 21.6991 34.5997 21.3519 34.3099C21.0048 34.0201 20.7703 33.6177 20.6895 33.1727L17.6107 16.2412C17.5703 16.0187 17.4531 15.8175 17.2795 15.6726C17.1059 15.5277 16.887 15.4483 16.6609 15.4482H14.4824"
        stroke="#192253"
        strokeWidth="1.93103"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2758 34.7585H22.9197"
        stroke="#192253"
        strokeWidth="1.93103"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6902 39.5864C24.0233 39.5864 25.104 38.5057 25.104 37.1726C25.104 35.8395 24.0233 34.7588 22.6902 34.7588C21.3571 34.7588 20.2764 35.8395 20.2764 37.1726C20.2764 38.5057 21.3571 39.5864 22.6902 39.5864Z"
        stroke="#192253"
        strokeWidth="1.93103"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2759 39.5861C35.609 39.5861 36.6896 38.5054 36.6896 37.1723C36.6896 35.8392 35.609 34.7585 34.2759 34.7585C32.9428 34.7585 31.8621 35.8392 31.8621 37.1723C31.8621 38.5054 32.9428 39.5861 34.2759 39.5861Z"
        stroke="#192253"
        strokeWidth="1.93103"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9696_32896">
        <rect
          width="30.8966"
          height="30.8966"
          fill="white"
          transform="translate(12.5518 12.5518)"
        />
      </clipPath>
    </defs>
  </ChkSvgWrapper>
);
