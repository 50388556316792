import { createTheme } from '@mui/material/styles';

import { borders } from './borders';
import { breakpoints } from './breakpoints';
import { palette } from './palette';
import { shadows } from './shadows';
import { tokens } from './tokens';
import { typography } from './typography';

export const theme = createTheme({
  iconSize: {
    sm: 16,
    md: 24,
    lg: 32,
    xl: 56,
  },
  palette,
  typography,
  spacing: Array.from(
    new Map([
      [0, 0],
      [1, 2],
      [2, 4],
      [3, 8],
      [4, 12],
      [5, 16],
      [6, 20],
      [7, 24],
      [8, 32],
      [9, 40],
      [10, 48],
      [11, 60],
    ]).values()
  ),
  borders,
  breakpoints,
  shadows,
  tokens,
});

export const SMALL_PHONE_RULE = '(max-height: 800px)';
export const SMALL_PHONE_MEDIA_QUERY = `@media${SMALL_PHONE_RULE}`;
