import { styled } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';

import type { ChkListIconProps } from './ChkListItem.types';

const LIST_ITEM_ICON_SIZE = '4.8rem';

export const ChkListIcon = ({ children, ...rest }: ChkListIconProps) => {
  return <StyledListItemIcon {...rest}>{children}</StyledListItemIcon>;
};

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: LIST_ITEM_ICON_SIZE,
  minWidth: LIST_ITEM_ICON_SIZE,
  height: LIST_ITEM_ICON_SIZE,
  marginTop: theme.spacing(0),
  boxSizing: 'border-box',
}));
