import type { Options } from 'qr-code-styling';

import { theme } from '@apps/checkout-components';

export const QR_CONFIGURATION = {
  width: 300,
  height: 300,
  dotsOptions: {
    type: 'square',
    color: theme.palette.navy500,
  },
  cornersSquareOptions: {
    type: 'square',
  },
  cornersDotOptions: {
    type: 'square',
  },
} satisfies Partial<Options>;
