import { Box, styled } from '@mui/material';

import { SMALL_PHONE_MEDIA_QUERY } from '../../theme';
import type { ChkCardProps } from './ChkCard.types';

export const ChkCard = ({ children, ...rest }: ChkCardProps) => (
  <CardContainer {...rest}>{children}</CardContainer>
);

const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(5),
  borderRadius: theme.borders.borderRadius2,
  border: theme.borders.borderWidth1,
  borderColor: theme.tokens.cardsOptionsBorderDefault,

  [`${SMALL_PHONE_MEDIA_QUERY}`]: {
    padding: theme.spacing(4),
  },
}));
