import {
  Box,
  keyframes,
  LinearProgress,
  styled,
  Typography,
  useTheme,
} from '@mui/material';

import type { ChkLoadingBarProps, TextProps } from './ChkLoadingBar.types';

export const ChkLoadingBar = ({
  leftText,
  rightText,
  ...props
}: ChkLoadingBarProps) => (
  <>
    {Boolean(leftText || rightText) && (
      <StyledTextContainer>
        <Text sx={{ marginRight: 'auto' }} children={leftText} />
        <Text sx={{ marginLeft: 'auto' }} children={rightText} />
      </StyledTextContainer>
    )}
    <ProgressBar {...props} />
  </>
);

const ANIMATION = keyframes`
  0%   { left: calc(100% - .5rem); }
  50%  { left: 50%; transform: translateX(-50%); }
  100% { left: calc(-36% + .5rem); }
 `;

const Text = ({ children, sx }: TextProps) => {
  const { tokens } = useTheme();
  if (typeof children === 'string') {
    return (
      <Typography sx={sx} variant="body2" color={tokens.coreMainPrimary}>
        {children}
      </Typography>
    );
  }

  return children;
};

const ProgressBar = ({ ...props }: ChkLoadingBarProps) => (
  <StyledLinearProgressContainer>
    <StyledLinearProgress {...props} />
  </StyledLinearProgressContainer>
);

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  '&.MuiLinearProgress-root': {
    borderRadius: theme.borders.borderRadius1,
    backgroundColor: theme.tokens.bottomSheetBackgroundLoader,
  },
  '&.MuiLinearProgress-root > .MuiLinearProgress-bar1Indeterminate': {
    display: 'none',
  },
  '&.MuiLinearProgress-root > .MuiLinearProgress-bar2Indeterminate': {
    backgroundColor: theme.tokens.bottomSheetBodyLink,
    animationName: ANIMATION,
    animationDirection: 'alternate-reverse',
    animationDuration: '750ms',
    animationTimingFunction: 'linear',
    animationDelay: '1ms',
    width: '36%',
    left: 'calc(-36% + .5rem)',
    borderRadius: theme.borders.borderRadius1,
  },
}));

const StyledLinearProgressContainer = styled(Box)(({ theme }) => ({
  padding: '.1rem',
  backgroundColor: theme.tokens.bottomSheetBackgroundLoader,
  borderRadius: theme.borders.borderRadius1,
}));

const StyledTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(5),
}));
