import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { ZodTypeAny } from 'zod';

import type { PollingOptions, SchemaReturnType } from '@apps/checkout-utils';
import {
  API_ENDPOINTS,
  captureException,
  captureSchemaValidationError,
  setSentryContext,
  useApiAbortable,
  usePolling,
} from '@apps/checkout-utils';

import type {
  StatusContextData,
  StatusContextProviderProps,
} from './status.types';

/**
 * This factory returns `StatusContextProvider`, `useStatusContext` and `withStatusContextProvider`;
 * Usage:
 * ````
 *   import { statusContextFactory } from '@apps/checkout-contexts';
 *   const { StatusContextProvider, useStatusContext, withStatusContextProvider } = statusContextFactory(StatusDataSchema);
 *
 *   export { StatusContextProvider, useStatusContext, withStatusContextProvider };
 * ````
 */

const statusContextFactory = <S extends ZodTypeAny>(
  schema: S,
  options?: PollingOptions
) => {
  const StatusContext = createContext<StatusContextData<
    SchemaReturnType<S>
  > | null>(null);

  const useStatusContext = () => {
    const context = useContext(StatusContext);

    if (!context) {
      throw new Error(
        'useStatusContext must be used within a <StatusContextProvider>'
      );
    }

    return context;
  };

  const StatusContextProvider = ({
    paymentRequestId,
    children,
  }: StatusContextProviderProps) => {
    const [statusPayload, setStatusPayload] =
      useState<StatusContextData<SchemaReturnType<S>>['statusPayload']>(null);

    const GET_STATUS_ENDPOINT = useMemo(
      () => API_ENDPOINTS.getStatus(paymentRequestId || ''),
      [paymentRequestId]
    );

    const { isLoading, executeRequest, error, status } = useApiAbortable<
      StatusContextData<SchemaReturnType<S>>['statusPayload']
    >(GET_STATUS_ENDPOINT, undefined);

    const {
      startPolling,
      stopPolling,
      error: pollingError,
    } = usePolling<SchemaReturnType<S>>(GET_STATUS_ENDPOINT, options);

    const requestForStatus = useCallback(
      async (options?: RequestInit) => {
        await executeRequest(options)
          .then((res) => {
            if (res) {
              const parsedResponse = schema.safeParse(res);
              setStatusPayload(res);

              if (!parsedResponse.success) {
                captureSchemaValidationError(parsedResponse.error, {
                  contexts: {
                    response: {
                      statusResponse: res,
                    },
                    error: {
                      schemaError: parsedResponse.error.issues,
                    },
                  },
                });
              }
            }
          })
          .catch((e) => {
            captureException(e);
          });
      },
      [executeRequest]
    );

    const setStatus = (status: SchemaReturnType<S>['status']) =>
      setStatusPayload((prevStatusPayload) => {
        return {
          ...prevStatusPayload,
          status,
        } as SchemaReturnType<S>;
      });

    useEffect(() => {
      setSentryContext({ key: 'status', val: statusPayload });
    }, [statusPayload]);

    return (
      <StatusContext.Provider
        value={{
          statusPayload,
          isLoading,
          isNotExecuted: status === 'not-executed',
          error: error || pollingError || null,
          setStatusPayload,
          requestForStatus,
          setStatus,
          startPolling,
          stopPolling,
        }}
      >
        {children}
      </StatusContext.Provider>
    );
  };

  /**
   * helper to be used when wrapping a component with StatusContextProvider is needed, out of JSX structures eg. in functions
   */
  const withStatusContextProvider = ({
    children,
    ...props
  }: StatusContextProviderProps) => (
    <StatusContextProvider {...props}>{children}</StatusContextProvider>
  );

  return {
    StatusContextProvider,
    useStatusContext,
    withStatusContextProvider,
  };
};

export { statusContextFactory };
