import type { ChkIconProps } from './ChkIcon.types';
import { ChkSvgWrapper } from './ChkIcon.utils';

export const ChkIconFingerprint = (iconProps: ChkIconProps) => (
  <ChkSvgWrapper {...iconProps}>
    <path
      d="M16.5 12.5C16.5035 15.4325 15.9321 18.3373 14.8181 21.05"
      stroke="#182251"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3544 9.50031C14.7468 8.82092 13.9473 8.34203 13.0616 8.12701C12.1759 7.912 11.2458 7.971 10.3943 8.29621C9.54292 8.62141 8.81031 9.19749 8.29347 9.94821C7.77663 10.6989 7.49994 11.5889 7.5 12.5003C7.50269 14.7874 6.92188 17.0374 5.8125 19.0375"
      stroke="#182251"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Vector_3"
      d="M9 4.01176C10.3577 3.53331 11.8103 3.38728 13.2361 3.5859C14.6619 3.78452 16.0193 4.32201 17.1945 5.15331C18.3698 5.98462 19.3287 7.08553 19.9908 8.36377C20.6529 9.64201 20.999 11.0603 21 12.4999C21.0011 14.5186 20.7766 16.5311 20.3306 18.4999"
      stroke="#182251"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Vector_4"
      d="M2.18457 16.2508C2.72385 15.0743 3.00209 13.795 3.0002 12.5008C2.99895 11.2344 3.2656 9.98202 3.78264 8.82595C4.29967 7.66989 5.0554 6.63627 6.0002 5.79297"
      stroke="#182251"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Vector_5"
      d="M10.367 20C10.1795 20.4144 9.97445 20.8206 9.75195 21.2188"
      stroke="#9599af"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Vector_6"
      d="M12 12.5C12.0022 14.0177 11.8132 15.5296 11.4375 17"
      stroke="#9599af"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </ChkSvgWrapper>
);
