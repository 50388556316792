import { statusContextFactory } from '@apps/checkout-contexts';

import { UKStatusPayloadSchema } from '../../global.schema';

const { StatusContextProvider, useStatusContext, withStatusContextProvider } =
  statusContextFactory(UKStatusPayloadSchema, {
    maxAttemptsBeforeDelay: 4,
    maxTime: 5000,
    delayUnit: 250,
  });

export { StatusContextProvider, useStatusContext, withStatusContextProvider };
