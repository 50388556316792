import { Box, styled, Typography, useTheme } from '@mui/material';

import { ChkButton } from '../ChkButton/ChkButton';
import { ChkCard } from '../ChkCard/ChkCard';
import { ChkLogoWrapper } from '../ChkLogoWrapper/ChkLogoWrapper';
import { ChkIconPencil } from '../icons';
import type { ChkBankCardProps } from './ChkBankCard.types';

export const ChkBankCard = ({
  bankName,
  dataTestIds,
  disabled,
  iconUrl,
  accountMask,
  onChangeBankClick,
  ...rest
}: ChkBankCardProps) => {
  const { tokens } = useTheme();

  return (
    <StyledChkCard disabled={disabled} {...rest}>
      <ChkLogoWrapper altText={`${bankName} logo`} iconUrl={iconUrl} />
      <ContentBox>
        <Typography variant="body3" color={tokens.cardsOptionsTextSubtitle}>
          Paying with
        </Typography>
        <Typography
          variant="body2"
          color={tokens.cardsOptionsTextTitle}
          sx={{ letterSpacing: '-0.3px' }}
          data-testid={dataTestIds?.bankName}
        >
          {bankName}
        </Typography>
        {accountMask && (
          <Typography
            variant="body3"
            color={tokens.cardsOptionsTextSubtitle}
            data-testid={dataTestIds?.accountMask}
          >
            {accountMask}
          </Typography>
        )}
      </ContentBox>
      {!disabled && (
        <ChkButton
          onClick={onChangeBankClick}
          startIcon={<ChkIconPencil />}
          variant="text"
          data-testid={dataTestIds?.changeBankButton}
        >
          <Typography variant="body2" fontWeight="bold">
            Change
          </Typography>
        </ChkButton>
      )}
    </StyledChkCard>
  );
};

const StyledChkCard = styled(ChkCard, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: ChkBankCardProps['disabled'] }>(({ disabled, theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  ...(disabled && { pointerEvents: 'none' }),
  '.MuiButtonBase-root': {
    padding: theme.spacing(0),
    letterSpacing: '-0.3px',
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  flex: 1,
  marginLeft: theme.spacing(5),
  marginRight: theme.spacing(3),
}));
