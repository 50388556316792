// @ts-ignore Prevents build failing with: Element implicitly has an 'any' type because type 'typeof globalThis' has no index signature.
globalThis.import_meta_env = JSON.parse('"import_meta_env_placeholder"');
// The line above is a (special-expression) placeholder, as per the docs:
// https://import-meta-env.org/guide/getting-started/introduction.html#special-expression

const parseNumberEnvVar = (value: string): number | undefined => {
  const number = Number(value);
  return isNaN(number) ? undefined : number;
};

export const CORE_API_BASE_URL = import.meta.env.CORE_API_BASE_URL;
export const SCA_API_BASE_URL = import.meta.env.SCA_API_BASE_URL;
export const CHECKOUT_API_BASE_URL = import.meta.env.CHECKOUT_API_BASE_URL;
export const VERSION = import.meta.env.VERSION;
export const APP_ENV = import.meta.env.APP_ENV;
export const SENTRY_DSN = import.meta.env.SENTRY_DSN;
export const MIXPANEL_TOKEN = import.meta.env.MIXPANEL_TOKEN;

export const SENTRY_TRACES_SAMPLE_RATE = parseNumberEnvVar(
  import.meta.env.SENTRY_TRACES_SAMPLE_RATE
);
