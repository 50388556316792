import { Box, Link, styled, Typography } from '@mui/material';
import { useCallback } from 'react';

import { trackEvent } from '@apps/checkout-utils';

import {
  POWER_GRID_PROVIDER_NAME,
  TERMS_AND_CONDITIONS_TEST_ID,
  UK_EVENTS,
} from '../../global.constants';
import { TAC_URL } from './TermsAndConditions.constants';
import type { TermsAndConditionsProps } from './TermsAndConditions.types';

export const TermsAndConditions = ({ provider }: TermsAndConditionsProps) => {
  const clickHandler = useCallback(() => {
    // TODO: double check with this is triggered twice
    trackEvent(UK_EVENTS.TERMS_AND_CONDITIONS_CLICK, {
      href: TAC_URL,
      route: window.location.pathname,
    });
  }, []);

  const DEFAULT_PROVIDER_TEXT = 'indicated connectivity provider';

  return (
    <StyledTermsAndConditionsWrapper>
      <StyledTypography data-testid={TERMS_AND_CONDITIONS_TEST_ID}>
        By continuing you agree to the{' '}
        <StyledLink
          href={TAC_URL}
          onClick={clickHandler}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
        >
          Terms and conditions and Privacy Policies
        </StyledLink>{' '}
        of Volt
        {provider === POWER_GRID_PROVIDER_NAME
          ? ''
          : ` and ${provider ?? DEFAULT_PROVIDER_TEXT}`}
        .
      </StyledTypography>
    </StyledTermsAndConditionsWrapper>
  );
};

const StyledTermsAndConditionsWrapper = styled(Box)({
  textAlign: 'center',

  '> span, > a': {
    display: 'inline-block',
    lineHeight: '165%',
  },
});

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.tokens.coreMainPrimary,

  '&:hover': {
    textDecorationColor: theme.tokens.coreBackgroundsPrimaryHover,
  },

  '&:visited': {
    color: theme.tokens.coreMainSecondary,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body3,
  color: theme.tokens.coreMainTertiary,
}));
