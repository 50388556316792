import { Box, Link, styled, Typography } from '@mui/material';

import { BOTTOM_TEXT, LINK_TEXT } from './SubText.constants';
import type { SubTextProps } from './SubText.types';

export const SubText = ({ onClick }: SubTextProps) => (
  <StyledSubTextContainer>
    <Typography variant="body2">{BOTTOM_TEXT}</Typography>
    <Link
      sx={{ textTransform: 'inherit', cursor: 'pointer' }}
      component="button"
      onClick={onClick}
    >
      <Typography variant="body2">{LINK_TEXT}</Typography>
    </Link>
  </StyledSubTextContainer>
);

const StyledSubTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: theme.spacing(7),
  width: '100%',
  gap: '.3rem',
  color: theme.tokens.coreMainPrimary,

  '> button': {
    color: theme.tokens.coreMainLinkDefault,
    textDecorationColor: theme.tokens.coreMainLinkHover,
  },
}));
