export const toCapitalCase = (text: string) =>
  `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

export const addParamToUrl = ({
  passedUrl,
  paramName,
  paramValue,
}: {
  passedUrl: string;
  paramName: string;
  paramValue: string;
}) => {
  const url = new URL(passedUrl);
  const params = new URLSearchParams(url.search);
  params.set(paramName, paramValue);

  return `${url.origin}${url.pathname}?${params.toString()}`;
};
