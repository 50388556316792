import { Box, Link, styled } from '@mui/material';

import {
  DRAWER_MOBILE_VIEW_TEST_ID,
  PAY_WITH_BUTTON_TEST_ID,
} from '../../../../global.constants';
import { useStatusContext } from '../../../../hooks/useStatusContext/useStatusContext';
import { TermsAndConditions } from '../../../TermsAndConditions/TermsAndConditions';
import { DRAWER_MOBILE_VIEW } from './DrawerMobileView.constants';
import type { DrawerMobileViewProps } from './DrawerMobileView.types';

export const DrawerMobileView = ({
  redirectUrl,
  bankName,
  iconUrl,
}: DrawerMobileViewProps) => {
  const { statusPayload } = useStatusContext();

  return (
    <StyledBox data-testid={DRAWER_MOBILE_VIEW_TEST_ID}>
      <TermsAndConditions
        provider={statusPayload?.details.providerName ?? ''}
      />
      <StyledLink href={redirectUrl} data-testid={PAY_WITH_BUTTON_TEST_ID}>
        {DRAWER_MOBILE_VIEW.payWith}
        {Boolean(iconUrl) && (
          <StyledIcon src={iconUrl} alt={`${bankName} icon`} />
        )}
      </StyledLink>
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  padding: `${theme.spacing(5)} ${theme.spacing(6)} ${theme.spacing(7)}`,
}));

// TODO: add a Link variant in ChkButton component or create ChkLink
const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(5),
  border: theme.borders.borderWidth1,
  borderRadius: theme.borders.borderRadius2,
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightBold,
  marginTop: theme.spacing(4),
  cursor: 'pointer',
  transition: `
      background-color 150ms ease-in,
      border-color 150ms ease-in,
      color 150ms ease-in
    `,
  color: theme.tokens.buttonDefaultTextDefault,
  backgroundColor: theme.tokens.buttonDefaultBackgroundDefault,
  '&:hover': {
    backgroundColor: theme.tokens.buttonDefaultBackgroundHover,
    borderColor: theme.tokens.buttonDefaultBackgroundHover,
  },
  '&:focus': {
    backgroundColor: theme.tokens.buttonDefaultBackgroundPressed,
    borderColor: theme.tokens.buttonDefaultBackgroundPressed,
  },
  textDecoration: 'none',
  width: '100%',
}));

const StyledIcon = styled('img')(({ theme }) => ({
  marginLeft: theme.spacing(3),
  width: theme.spacing(7),
  height: theme.spacing(7),
}));
