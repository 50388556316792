import { useEffect, useMemo, useState } from 'react';
import type { ReactNode } from 'react';

import { getComponentByStatus } from '@apps/checkout-contexts';

import { COMMON_HEADERS_KEYS } from '@apps/checkout-utils';

import { X_VOLT_API_VERSION } from '../global.constants';
import type { UkStatus } from '../global.types';
import { useAppLevelMixpanelEvents } from '../hooks/useAppLevelMixpanelEvents/useAppLevelMixpanelEvents';
import { useBanksContext } from '../hooks/useBanksContext/useBanksContext';
import { useStatusContext } from '../hooks/useStatusContext/useStatusContext';
import { BankRedirectMobileView } from '../views/BankRedirectMobileView/BankRedirectMobileView';
import { BankSelectionView } from '../views/BankSelectionView/BankSelectionView';
import { EducationalView } from '../views/EducationalView/EducationalView';
import { SummaryView } from '../views/SummaryView/SummaryView';

export function App() {
  const { statusPayload, requestForStatus, isLoading, isNotExecuted } =
    useStatusContext();
  const { requestForBanksList } = useBanksContext();
  useAppLevelMixpanelEvents();
  const [isMobileViewFlowActive, setIsMobileViewFlowActive] = useState(false);

  useEffect(() => {
    if (statusPayload?.status === 'BANK_REDIRECT_MOBILE') {
      setIsMobileViewFlowActive(true);
    }
  }, [statusPayload?.status]);

  const STATUS_MAP = useMemo(
    () =>
      ({
        EDUCATIONAL: <EducationalView variant="EDUCATIONAL" />,
        PRESELECTED_EDUCATIONAL: (
          <EducationalView variant="PRESELECTED_EDUCATIONAL" />
        ),
        BANK_SELECTION: <BankSelectionView />,
        BANK_REDIRECT: <SummaryView isPollingOn={!isMobileViewFlowActive} />,
        BANK_REDIRECT_MOBILE: <BankRedirectMobileView />,
        SUMMARY: <SummaryView isPollingOn={!isMobileViewFlowActive} />,
        UNKNOWN: <SummaryView isPollingOn={!isMobileViewFlowActive} />,
      } satisfies Record<UkStatus, ReactNode>),
    [isMobileViewFlowActive]
  );

  const view = useMemo(() => {
    return getComponentByStatus<UkStatus>({
      status: statusPayload?.status || null,
      componentsMap: STATUS_MAP,
      isLoading,
      isReady: !isNotExecuted,
    });
  }, [STATUS_MAP, statusPayload?.status, isLoading, isNotExecuted]);

  useEffect(() => {
    requestForStatus({
      headers: {
        [COMMON_HEADERS_KEYS.X_VOLT_API_VERSION]: X_VOLT_API_VERSION,
      },
    });
  }, [requestForStatus]);

  useEffect(() => {
    requestForBanksList();
  }, [requestForBanksList]);

  return view;
}

export default App;
