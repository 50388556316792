import type { ReactNode } from 'react';

import { ChkGenericError, ChkGenericLoader } from '@apps/checkout-components';

import { ERROR_TEST_ID, LOADER_TEST_ID } from './status.constants';
import type { GetComponentByStatus } from './status.types';

const getComponentByStatus: GetComponentByStatus = ({
  status,
  isLoading,
  componentsMap,
  loadingComponent,
  errorComponent,
  isReady = true,
}) => {
  if (!isReady) return null;

  if (isLoading) {
    return (
      loadingComponent || <ChkGenericLoader data-testid={LOADER_TEST_ID} />
    );
  }

  const errorComp = errorComponent || (
    <ChkGenericError data-testid={ERROR_TEST_ID} />
  );

  if (!status) {
    return errorComp;
  }

  const component = componentsMap[status] as ReactNode;

  if (!component) {
    return errorComp;
  }

  return component;
};

export { getComponentByStatus };
