const isUuid = (id: string): boolean => {
  const uuidRegex =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

  return uuidRegex.test(id);
};

/**
 *
 * @returns The first chunk in the current URL.pathname matching then UUID pattern
 */
export const getPaymentRequestId = (): string | undefined =>
  (new URL(document.URL).pathname || '')
    .split('/')
    .filter((chunk) => isUuid(chunk)) // remove non-uuid chunks
    .shift(); // return the first element or undefined
