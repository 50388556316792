// eslint-disable-next-line filename-rules/match
import { useTheme } from '@mui/material';
import type { ReactNode } from 'react';

import type { ChkIconProps } from './ChkIcon.types';

type SvgWrapperProps = {
  children: ReactNode;
} & ChkIconProps;

export const ChkSvgWrapper = ({
  children,
  size = 'md',
  ...attributes
}: SvgWrapperProps) => {
  const theme = useTheme();
  const iconSize = theme.iconSize[size];

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill="none"
      {...attributes}
    >
      {children}
    </svg>
  );
};
