import { FormControlLabel, Radio, styled } from '@mui/material';
import { useMemo, useState } from 'react';

import type { ChkRadioButtonProps } from './ChkRadioButton.types';

export const ChkRadioButton = ({
  value,
  name,
  inputProps,
  size,
  onChange,
  checked,
  ...rest
}: ChkRadioButtonProps) => {
  const [isChecked, setIsChecked] = useState(checked || false);
  const radioSize = useMemo(() => {
    const sizeMap = { lg: 'medium', sm: 'small' } as const;
    return sizeMap[size];
  }, [size]);

  return (
    <StyledFormControlLabel
      control={
        <StyledRadio
          value={value}
          name={name}
          inputProps={inputProps}
          size={radioSize}
          disableRipple
        />
      }
      onChange={(event, checked) => {
        setIsChecked(checked);
        onChange && onChange(event, checked);
      }}
      isChecked={isChecked}
      checked={checked}
      {...rest}
    />
  );
};

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'isChecked',
})<{ isChecked: boolean }>(({ theme, isChecked, checked }) => ({
  '&.MuiFormControlLabel-root': {
    gap: theme.spacing(4),
    margin: theme.spacing(0),
    width: '100%',

    '.MuiFormControlLabel-label': {
      ...theme.typography.body2,
      ...((checked === undefined ? isChecked : checked)
        ? { color: theme.tokens.controlsTextActive }
        : { color: theme.tokens.controlsTextDefault }),
    },

    '&:hover:not(.Mui-disabled)': {
      '.MuiFormControlLabel-label': {
        color: theme.tokens.controlsTextDefault,
      },

      '.MuiRadio-root:not(.Mui-checked).MuiRadio-colorPrimary': {
        color: theme.tokens.controlsInnerHover,

        'svg:last-child': {
          color: theme.tokens.controlsInnerActive,
          opacity: '0.2',
          transform: 'scale(1)',
        },
      },
    },

    '&:focus:not(.Mui-disabled), &:active:not(.Mui-disabled), &:focus-within:not(.Mui-disabled)':
      {
        '.MuiFormControlLabel-label': {
          color: theme.tokens.controlsTextActive,
        },

        '.MuiRadio-root.MuiRadio-colorPrimary': {
          color: theme.tokens.controlsBorderActive,

          'svg:last-child': {
            color: theme.tokens.controlsInnerActive,
          },
        },
      },

    '.Mui-disabled': {
      color: theme.tokens.controlsTextDisabled,
    },
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.MuiRadio-root': {
    padding: theme.spacing(0),

    color: theme.tokens.controlsBorderDefault,

    '&.MuiRadio-sizeSmall svg': {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },

    '&.Mui-checked': {
      'svg:last-child': {
        color: theme.tokens.controlsInnerActive,
      },
    },

    '&.Mui-disabled': {
      color: theme.tokens.controlsBorderDisabled,

      'svg:last-child': {
        color: theme.tokens.controlsBorderDisabled,
      },
    },
  },
}));
