import { Box, styled, Typography } from '@mui/material';

import type { ChkHeaderProps } from './ChkHeader.types';

export const ChkHeader = ({
  componentStart,
  componentEnd,
  children,
  ...rest
}: ChkHeaderProps) => (
  <MainWrapper {...rest}>
    <ComponentWrapper>{componentStart}</ComponentWrapper>
    <Text>{children}</Text>
    <ComponentWrapper>{componentEnd}</ComponentWrapper>
  </MainWrapper>
);

const MainWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  color: theme.tokens.coreMainPrimary,
}));

const ComponentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: theme.spacing(7),
  height: theme.spacing(7),
}));

const Text = ({ children }: { children: ChkHeaderProps['children'] }) => {
  if (typeof children === 'string') {
    return (
      <Typography fontWeight="bold" variant="h3">
        {children}
      </Typography>
    );
  }

  return children;
};
