import Fuse from 'fuse.js';
import { useCallback, useMemo } from 'react';

import { FUSE_DEFAULT_OPTIONS } from './fuzzySearch.constants';
import type {
  FuzzySearchOptions,
  FuzzySearchReturn,
} from './fuzzySearch.types';

const useFuzzySearch = <T>(
  list: T[],
  options?: FuzzySearchOptions<T>
): FuzzySearchReturn<T> => {
  const fuse = useMemo(
    () => new Fuse<T>(list, { ...FUSE_DEFAULT_OPTIONS, ...options }),
    [list, options]
  );

  const find = useCallback(
    (query: string): T[] => fuse.search(query).map((res) => res.item),
    [fuse]
  );

  return {
    find,
  };
};

export { useFuzzySearch };
