import { styled, Typography, useTheme } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';

import type { ChkListTextProps } from './ChkListItem.types';

export const ChkListText = ({
  primaryText,
  secondaryText,
  ...rest
}: ChkListTextProps) => {
  const { tokens } = useTheme();
  return (
    <StyledListText
      primary={
        <Typography variant="body1" color={tokens.coreMainPrimary}>
          {primaryText}
        </Typography>
      }
      secondary={
        <Typography
          variant="body2"
          sx={{ color: tokens.coreMainSecondary, letterSpacing: '-0.3px' }}
        >
          {secondaryText}
        </Typography>
      }
      {...rest}
    />
  );
};

const StyledListText = styled(ListItemText)(({ theme }) => ({
  marginTop: 0,
  marginBottom: 0,
  marginLeft: theme.spacing(5),
}));
