import { Box, styled } from '@mui/material';
import { useMemo } from 'react';

import { ChkIconCartTile, ChkIconVoltTile } from '@apps/checkout-components';

import { useBreakpoints } from '../../hooks/useBreakpoints/useBreakpoints';
import {
  CART_TILE_TEST_ID,
  VOLT_TILE_TEST_ID,
} from './EducationalIcons.constants';

export const EducationalIcons = () => {
  const { isLessThanXXS } = useBreakpoints();
  const iconSize = useMemo(
    () => (isLessThanXXS ? 'lg' : 'xl'),
    [isLessThanXXS]
  );

  return (
    <IconsContainer>
      <IconContainer>
        <ChkIconCartTile
          data-testid={CART_TILE_TEST_ID}
          size={iconSize}
          viewBox="0 0 56 56"
        />
      </IconContainer>
      <IconContainer>
        <ChkIconVoltTile
          data-testid={VOLT_TILE_TEST_ID}
          size={iconSize}
          viewBox="0 0 56 56"
        />
      </IconContainer>
    </IconsContainer>
  );
};

const IconContainer = styled(Box)(({ theme }) => ({
  boxShadow: '0px 8px 28px 0px rgba(25, 34, 83, 0.16)',
  borderRadius: theme.spacing(4),
  lineHeight: theme.spacing(0),
}));

const IconsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(4),
}));
