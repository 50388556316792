import { Dialog, styled } from '@mui/material';

import type { ChkModalProps } from './ChkModal.types';

export const ChkModal = ({ ...props }: ChkModalProps) => {
  return <StyledDialog {...props} />;
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    padding: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(8)}`,
    maxWidth: `calc(${theme.breakpoints.values['xs']}px - (2 * ${theme.spacing(
      10
    )}))`,
    borderRadius: theme.borders.borderRadius2,
  },
}));
