import type { DividerProps } from '@mui/material';
import {
  Divider as MuiDivider,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  styled,
} from '@mui/material';
import { Fragment } from 'react';

import { SMALL_PHONE_MEDIA_QUERY } from '../../theme';
import { ChkCard } from '../ChkCard/ChkCard';
import type {
  ChkSummaryProps,
  ChkSummaryRowItemProps,
} from './ChkSummary.types';
import { addTestId } from './ChkSummary.utils';

const ChkSummaryRowItem = ({ data }: ChkSummaryRowItemProps) => {
  return (
    <ListItem>
      <ListItemText disableTypography>{data.label}</ListItemText>
      <ListItemText disableTypography data-testid={data.dataTestId}>
        {data.value}
      </ListItemText>
    </ListItem>
  );
};

export const ChkSummary = ({ summaryItems = [], ...rest }: ChkSummaryProps) => {
  return (
    <ChkCard {...rest}>
      <List>
        {summaryItems.map((item, index) => {
          const isSecondLastItem = index === summaryItems.length - 2;
          const moreThanOneItem = summaryItems.length > 1;
          const shouldRenderSeparator = isSecondLastItem && moreThanOneItem;

          return (
            <Fragment key={`${item.label}-${index}`}>
              <ChkSummaryRowItem data={addTestId(item)} />
              {shouldRenderSeparator && (
                <Divider component="li" key="separator" />
              )}
            </Fragment>
          );
        })}
      </List>
    </ChkCard>
  );
};

const List = styled(MuiList)(({ theme }) => ({
  padding: theme.spacing(0),
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: theme.spacing(5),

  [`${SMALL_PHONE_MEDIA_QUERY}`]: {
    gap: theme.spacing(4),
  },
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
  color: theme.tokens.coreMainPrimary,
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: theme.spacing(8),

  [`${SMALL_PHONE_MEDIA_QUERY}`]: {
    gap: theme.spacing(4),
  },

  '&:last-child .MuiListItemText-root': {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  ...theme.typography.body2,
  margin: theme.spacing(0),
  flex: '0 0 auto',

  '& + &': {
    textAlign: 'right',
    flex: '1 1 auto',
    wordBreak: 'break-word',
  },
}));

const Divider = styled(MuiDivider)<DividerProps>(({ theme }) => ({
  borderColor: theme.tokens.coreBorderLight,
}));
