import { Box } from '@mui/material';
import { useDebouncedState } from '@react-hookz/web';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { ChkTabsProps } from '@apps/checkout-components';
import { ChkSearchInput, ChkTabs, theme } from '@apps/checkout-components';

import {
  API_ENDPOINTS,
  getPaymentRequestId,
  registerGlobalProperties,
  trackEvent,
  useApi,
  useFuzzySearch,
} from '@apps/checkout-utils';

import {
  SEARCH_BANK_INPUT_TEST_ID,
  UK_EVENTS,
} from '../../../global.constants';
import type { UkBank } from '../../../global.types';
import { useBanksContext } from '../../../hooks/useBanksContext/useBanksContext';
import { useStatusContext } from '../../../hooks/useStatusContext/useStatusContext';
import { BanksList } from '../BanksList/BanksList';
import { SubText } from '../SubText/SubText';
import {
  BANK_FILTER_OPTIONS,
  PATCH_ARGS,
  PLACEHOLDER,
  TABS,
} from './BanksFilter.constants';
import type { PatchBankResponse, SearchedQueries } from './BanksFilter.types';

export const BanksFilter = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isSubText, setIsSubText] = useState(true);
  const subTextClickHandler = () => {
    if (searchInputRef.current) {
      searchInputRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
      searchInputRef.current.focus();
    }
  };

  const { banksPayload, error: banksError } = useBanksContext();
  const [searchValue, setSearchValue] = useState('');
  const { setStatusPayload } = useStatusContext();
  const [searchedQueries, setSearchedQueries] =
    useDebouncedState<SearchedQueries>([], 1000, 1000);
  const { find } = useFuzzySearch<UkBank>(
    banksPayload || [],
    BANK_FILTER_OPTIONS
  );
  const searchHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const blurHandler = useCallback(() => {
    if (searchedQueries.length > 0) {
      trackEvent(UK_EVENTS.BANK_SEARCHED, { searchedQueries });
    }

    setSearchedQueries([]);
    setIsSubText(true);
  }, [searchedQueries, setSearchedQueries]);

  const banks = useMemo(
    () =>
      (searchValue ? find(searchValue) : banksPayload)?.filter(
        (bank) => bank.types.length > 0
      ),
    [searchValue, find, banksPayload]
  );

  const { executeRequest, isLoading: isPatchLoading } =
    useApi<PatchBankResponse>(
      API_ENDPOINTS.patchBank(getPaymentRequestId() ?? '')
    );
  const sendBankSelectMixpanelEvent = useCallback((bank: UkBank) => {
    registerGlobalProperties({
      bankName: bank.name,
      bankId: bank.id,
    });

    trackEvent(UK_EVENTS.BANK_SELECT);
  }, []);

  const bankClickHandler = useCallback(
    async (bank: UkBank) => {
      sendBankSelectMixpanelEvent(bank);
      const response = await executeRequest({
        ...PATCH_ARGS,
        body: JSON.stringify({
          bank: bank.id,
        }),
      });

      if (response) {
        setStatusPayload({ ...response, status: 'SUMMARY' });
      }
    },
    [executeRequest, setStatusPayload, sendBankSelectMixpanelEvent]
  );

  const focusHandler = useCallback(() => {
    setIsSubText(false);
  }, []);

  const tabsConfig: ChkTabsProps['tabs'] = TABS.map(({ label, tag }) => ({
    name: label,
    content: (
      <BanksList
        isError={Boolean(banksError)}
        onBankTileClick={bankClickHandler}
        disableTiles={isPatchLoading}
        banks={banks?.filter((bank) => bank.types.includes(tag)) || []}
      />
    ),
  }));

  useEffect(() => {
    if (searchValue.length) {
      setSearchedQueries((prevSearchedQueries) => [
        ...prevSearchedQueries,
        { query: searchValue, resultsCount: banks?.length || 0 },
      ]);
    }
  }, [searchValue, banks, setSearchedQueries]);

  return (
    <>
      <ChkSearchInput
        placeholder={PLACEHOLDER}
        value={searchValue}
        fullWidth
        clearInputValue={() => {
          setSearchValue('');
        }}
        onChange={searchHandler}
        disabled={Boolean(banksError)}
        onBlur={blurHandler}
        inputRef={searchInputRef}
        onFocus={focusHandler}
        inputProps={{ 'data-testid': SEARCH_BANK_INPUT_TEST_ID }}
      />

      <Box sx={{ paddingTop: theme.spacing(4) }}>
        <ChkTabs tabs={tabsConfig} />
      </Box>

      {!banksError && isSubText && <SubText onClick={subTextClickHandler} />}
    </>
  );
};
