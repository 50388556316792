import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useApiAbortable } from '../useApi/useApi.hook';
import { DEFAULT_POLLING_OPTIONS } from './usePolling.constants';
import type {
  GetSleepTimeArgs,
  PollingOptions,
  StartPollingArgs,
} from './usePolling.types';

const getSleepTime = ({
  requestsCount,
  delayUnit,
  maxTime,
  maxAttemptsBeforeDelay,
}: GetSleepTimeArgs) => {
  const min = Math.max(delayUnit * (requestsCount - maxAttemptsBeforeDelay), 0);

  return Math.min(min, maxTime);
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const isAbortError = (err: unknown) =>
  err instanceof Error && err.name === 'AbortError';

/**
 * Hook for endpoint polling
 * @param endpoint endpoint to start polling
 * @param pollingOptions polling options
 * @returns
 */

export const usePolling = <T>(
  endpoint: string,
  pollingOptions?: Partial<PollingOptions>,
  apiBaseUrl?: string
) => {
  const options = useMemo(
    () => ({ ...DEFAULT_POLLING_OPTIONS, ...pollingOptions }),
    [pollingOptions]
  );

  const requestsCount = useRef(0);
  const shouldRequestAgain = useRef(false);
  const argsRef = useRef<StartPollingArgs<T> | null>(null);
  const abortRef = useRef<AbortController>();
  const [isPolling, setIsPolling] = useState(false);

  const { executeRequest, error, ...rest } = useApiAbortable<T>(
    endpoint,
    undefined,
    apiBaseUrl
  );

  const startPolling = useCallback((args: StartPollingArgs<T> = {}) => {
    shouldRequestAgain.current = true;
    requestsCount.current = 0;
    argsRef.current = args;
    setIsPolling(true);
  }, []);

  const stopPolling = useCallback(() => {
    setIsPolling(false);

    shouldRequestAgain.current = false;
    argsRef.current = null;
  }, []);

  const initiateLoop = useCallback(async () => {
    while (!abortRef.current?.signal.aborted) {
      if (!shouldRequestAgain.current) {
        break;
      }

      try {
        requestsCount.current++;
        const { handleResponse, requestOptions } = argsRef.current || {};
        const response = await executeRequest({
          ...requestOptions,
          signal: abortRef.current?.signal,
        });

        if (handleResponse) {
          handleResponse(response);
        }
      } catch (error) {
        if (isAbortError(error)) {
          break;
        }

        console.error(error);
      }

      const time = getSleepTime({
        requestsCount: requestsCount.current,
        delayUnit: options.delayUnit,
        maxTime: options.maxTime,
        maxAttemptsBeforeDelay: options.maxAttemptsBeforeDelay,
      });

      if (time) {
        await sleep(time);
      }
    }
  }, [
    executeRequest,
    options.maxAttemptsBeforeDelay,
    options.maxTime,
    options.delayUnit,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    abortRef.current = abortController;

    if (isPolling) {
      initiateLoop();
    }

    return () => {
      abortController.abort();
    };
  }, [isPolling, initiateLoop]);

  return {
    startPolling,
    stopPolling,
    error: isAbortError(error) ? null : error,
    ...rest,
  };
};
