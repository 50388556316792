// copy/paste from Checkout v2
export class GoogleTranslationObserver {
  public callback: (arg: string) => void;
  config: { attributes: boolean };
  observer: undefined | MutationObserver;
  observeElement: HTMLElement | null;

  constructor(callback: (arg: string) => void) {
    this.callback = callback;
    this.config = { attributes: true };
    this.observer = undefined;
    this.observeElement = document.querySelector('html');

    if (typeof MutationObserver === 'function' && this.observeElement) {
      this.observer = new MutationObserver((mutationList) =>
        this.mutationCallback(mutationList)
      );
      this.observer.observe(this.observeElement, this.config);
    }
  }

  private mutationCallback(mutationList: MutationRecord[]) {
    const classMutation = mutationList.find(
      (mutation) =>
        mutation.attributeName === 'class' && mutation.type === 'attributes'
    );

    const observeElement = document.querySelector('html');

    if (
      observeElement &&
      (classMutation?.target as HTMLElement)?.className.substring(0, 9) ===
        'translate'
    ) {
      const language = observeElement.getAttribute('lang') || '';
      this.callback(language);
    }
  }

  public disconnect(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
