import type React from 'react';

import '../assets/fonts/stylesheet.css';

export const typographyKeys = [
  'h1',
  'h2',
  'h3',
  'body1',
  'body2',
  'body3',
  'body4',
] as const;

type TypographyKeys = (typeof typographyKeys)[number];

declare module '@mui/material/styles' {
  type PaletteTypography = {
    [T in TypographyKeys]: React.CSSProperties;
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyVariants extends PaletteTypography {}

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyVariantsOptions extends PaletteTypography {}
}

declare module '@mui/material/Typography' {
  type PaletteTypography = {
    [T in TypographyKeys]: true;
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyPropsVariantOverrides extends PaletteTypography {}
}

// This one should be extended with fallback fonts  - to be decided
const fontFamily = 'Graphik';

const baseFontSize = Number(
  window
    .getComputedStyle(document.body, null)
    .getPropertyValue('font-size')
    .replace('px', '')
);

export const typography = {
  htmlFontSize: baseFontSize,
  fontWeightBold: 500,
  fontWeightMedium: 400,
  h1: {
    fontSize: '2.8rem',
    fontFamily: fontFamily,
    lineHeight: '1.55',
  },
  h2: {
    fontSize: '2.4rem',
    fontFamily: fontFamily,
    lineHeight: '1.55',
  },
  h3: {
    fontSize: '2rem',
    fontFamily: fontFamily,
    lineHeight: '1.65',
  },
  body1: {
    fontSize: '1.6rem',
    fontFamily: fontFamily,
    lineHeight: '1.65',
  },
  body2: {
    fontSize: '1.4rem',
    fontFamily: fontFamily,
    lineHeight: '1.65',
  },
  body3: {
    fontSize: '1.2rem',
    fontFamily: fontFamily,
    lineHeight: '1.65',
  },
  body4: {
    fontSize: '1rem',
    fontFamily: fontFamily,
    lineHeight: '1.65',
  },
};
