import { z } from 'zod';

import { STATUSES } from './global.constants';

export const BankAssetSchema = z.enum(['icon', 'logo']);
export const UkStatusSchema = z.nativeEnum(STATUSES);

export const UkBankSchema = z.object({
  id: z.string(),
  name: z.string(),
  assets: z.record(BankAssetSchema, z.string()),
  searchAdditional: z.string().nullable(),
  types: z.array(z.enum(['PERSONAL', 'BUSINESS'])),
  active: z.boolean(),
  deactivationReason: z.number().nullable(),
  editable: z.boolean().optional(),
});

export const UkBankForStatusSchema = UkBankSchema.pick({
  id: true,
  editable: true,
  name: true,
  assets: true,
});

export const UKStatusPayloadSchema = z.object({
  status: UkStatusSchema,
  details: z.object({
    redirectUrl: z.string().nullable(),
    bank: UkBankForStatusSchema.optional(),
    providerName: z.string().nullable().optional(),
    licence: z.string().nullable().optional(),
  }),
});
