import { useMediaQuery } from '@mui/material';
import { useCallback } from 'react';

import type { UseDetectionReturnValue } from './useDetection.types';

export const useDetection = (): UseDetectionReturnValue => {
  const isMobile = useMediaQuery('(pointer:coarse), (hover:none)');

  const isBrowserSafari = useCallback(
    (userAgent: string) => /^((?!chrome|android).)*safari/i.test(userAgent),
    []
  );

  return { isBrowserSafari, isMobile };
};
