import { Fade, Slide, Snackbar, styled } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import type { ReactElement } from 'react';

import { ChkIconToastClose } from '../icons';
import type { ChkToastProps, StyledIconWrapperProps } from './ChkToast.types';

const SlideAndFadeTransition = (
  props: TransitionProps & { children: ReactElement }
) => {
  return (
    <Slide
      {...props}
      direction="up"
      timeout={600}
      easing="cubic-bezier(0.2, 0, 0.2, 1)"
    >
      {/* div needed here to make Slide work with Fade */}
      <div>
        <Fade {...props} timeout={600} />
      </div>
    </Slide>
  );
};

export const ChkToast = ({
  open,
  autoHideDuration,
  onClose,
  children,
  alignCloseIcon,
  ...rest
}: ChkToastProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration || 3000}
      onClose={onClose}
      TransitionComponent={SlideAndFadeTransition}
      {...rest}
    >
      <StyledSnackbarContent>
        {children}
        <StyledIconWrapper alignSelf={alignCloseIcon} onClick={onClose}>
          <ChkIconToastClose />
        </StyledIconWrapper>
      </StyledSnackbarContent>
    </Snackbar>
  );
};

const StyledSnackbarContent = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.tokens.toastBackground,
  color: theme.tokens.toastText,
  letterSpacing: '-0.3px',
  borderRadius: theme.spacing(3),
  padding: `${theme.spacing(5)} ${theme.spacing(6)}`,
  gap: theme.spacing(3),
}));

const StyledIconWrapper = styled('div')<StyledIconWrapperProps>(
  ({ alignSelf, theme }) => ({
    cursor: 'pointer',
    display: 'flex',
    alignSelf: alignSelf || 'center',
    color: theme.tokens.toastIcon,
  })
);
