import { COMMON_HEADERS_KEYS } from '@apps/checkout-utils';
import type { FuzzySearchOptions } from '@apps/checkout-utils';

import { X_VOLT_API_VERSION } from '../../../global.constants';
import type { UkBank } from '../../../global.types';

export const TABS: { label: string; tag: 'PERSONAL' | 'BUSINESS' }[] = [
  {
    label: 'Personal',
    tag: 'PERSONAL',
  },
  {
    label: 'Business',
    tag: 'BUSINESS',
  },
];

export const PLACEHOLDER = 'Search';
export const BANK_FILTER_OPTIONS: FuzzySearchOptions<UkBank> = {
  keys: [
    { name: 'name', weight: 0.5 },
    { name: 'searchAdditional', weight: 1 },
  ],
};

export const PATCH_ARGS: RequestInit = {
  method: 'PATCH',
  headers: {
    [COMMON_HEADERS_KEYS.X_VOLT_API_VERSION]: X_VOLT_API_VERSION,
    [COMMON_HEADERS_KEYS.CONTENT_TYPE]: 'application/json',
  },
};
