import type { ChkIconProps } from './ChkIcon.types';
import { ChkSvgWrapper } from './ChkIcon.utils';

export const ChkIconBankPlaceholder = ({ ...rest }: ChkIconProps) => (
  <ChkSvgWrapper {...rest} fill="#e4e6ee">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 10.5001L12.75 19L11.25 19L11.25 10.5001L12.75 10.5001Z"
      fill="#b8baca"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3907 4.10816C12.152 3.96395 11.8479 3.96395 11.6093 4.10816L2.34104 9.70816C2.0656 9.87458 1.93834 10.1952 2.02889 10.4946C2.11944 10.7939 2.40598 11 2.73176 11H5.00002L5 18.5H2.70595C2.31607 18.5 2 18.8358 2 19.25C2 19.6642 2.31607 20 2.70595 20H21.294C21.6839 20 22 19.6642 22 19.25C22 18.8358 21.6839 18.5 21.294 18.5H19L19 11H21.2682C21.594 11 21.8806 10.7939 21.9711 10.4946C22.0617 10.1952 21.9344 9.87458 21.659 9.70816L12.3907 4.10816ZM17.5 11H6.5L6.49998 18.5H17.5L17.5 11ZM12 5.52792L18.7395 9.6H5.26054L12 5.52792Z"
      fill="#b8baca"
    />
  </ChkSvgWrapper>
);
