import { Box, styled } from '@mui/material';

import { ChkQrCode } from '@apps/checkout-components';

import { QR_CODE_ICON_TEST_ID } from '../../../../global.constants';
import { QR_CONFIGURATION } from './DrawerQrCode.constants';
import type { DrawerQrCodeProps } from './DrawerQrCode.types';

export const DrawerQrCode = ({ url, iconUrl }: DrawerQrCodeProps) => (
  <StyledBox>
    <StyledChkQrCode url={url} config={QR_CONFIGURATION} />
    {iconUrl && (
      <StyledLogoBox>
        <StyledLogo src={iconUrl} data-testid={QR_CODE_ICON_TEST_ID} alt="" />
      </StyledLogoBox>
    )}
  </StyledBox>
);

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

const StyledChkQrCode = styled(ChkQrCode)(({ theme }) => ({
  width: '15.6rem',
  height: '15.6rem',
  padding: theme.spacing(3),
  backgroundColor: theme.tokens.bottomSheetHandoffBackgroundHighlight,
  canvas: {
    width: '100%',
  },
  borderRadius: theme.borders.borderRadius2,
}));

const StyledLogoBox = styled(Box)(({ theme }) => ({
  borderRadius: '100%',
  width: '3.2rem',
  height: '3.2rem',
  backgroundColor: theme.tokens.bottomSheetHandoffBackgroundHighlight,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledLogo = styled('img')(() => ({
  width: '2rem',
  height: '2rem',
  filter: 'brightness(0%)',
}));
