import type { ChkIconProps } from './ChkIcon.types';
import { ChkSvgWrapper } from './ChkIcon.utils';

export const ChkIconNewShield = (iconProps: ChkIconProps) => (
  <ChkSvgWrapper {...iconProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.52797 4.96154C3.50015 4.96154 3.47964 4.97241 3.46941 4.98219C3.46465 4.98675 3.46274 4.99033 3.46206 4.99191C3.4617 4.99274 3.46157 4.99323 3.46154 4.99343V10.5976C3.46154 14.3634 5.11682 16.8454 6.94448 18.4577C8.79092 20.0865 10.8142 20.8238 11.4754 21.0332L11.484 21.0359L11.484 21.036C11.494 21.0393 11.506 21.0393 11.516 21.036L11.5237 21.0334L11.5237 21.0335C12.1855 20.823 14.2089 20.0845 16.0554 18.455C17.8832 16.8421 19.5385 14.36 19.5385 10.5957V4.99343C19.5384 4.99323 19.5383 4.99274 19.5379 4.99191C19.5373 4.99033 19.5354 4.98675 19.5306 4.98219C19.5204 4.97241 19.4998 4.96154 19.472 4.96154H3.52797ZM2.45912 3.92607C2.7479 3.64982 3.13293 3.5 3.52797 3.5H19.472C19.8671 3.5 20.2521 3.64982 20.5409 3.92607C20.8308 4.20338 21 4.58659 21 4.99338V10.5957C21 14.856 19.1022 17.7156 17.0225 19.5509C14.9644 21.367 12.7292 22.1835 11.9698 22.4253C11.6651 22.5248 11.3355 22.5249 11.0308 22.4255C10.2706 22.1845 8.03556 21.3691 5.97763 19.5537C3.89775 17.719 2 14.8593 2 10.5976V4.99338C2 4.58659 2.16923 4.20338 2.45912 3.92607Z"
      fill="#182251"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5435 8.648C16.8104 8.93593 16.7837 9.37708 16.4837 9.63333L10.4004 14.8307L7.51712 12.3673C7.21719 12.1111 7.19044 11.6699 7.45737 11.382C7.72429 11.094 8.18382 11.0684 8.48375 11.3246L10.4004 12.9622L15.5171 8.59063C15.8171 8.33439 16.2766 8.36007 16.5435 8.648Z"
      fill="#9599af"
    />
  </ChkSvgWrapper>
);
