import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import type { SyntheticEvent } from 'react';

import type { ChkTabPanelProps, ChkTabsProps } from './ChkTabs.types';

export const ChkTabs = ({ tabs }: ChkTabsProps) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = useCallback((_: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <StyledTabs value={tabValue} onChange={handleTabChange}>
        {tabs.map(({ name }, index) => (
          <StyledTab
            disableTouchRipple
            key={`tab-title-${name}-${index}`}
            label={name}
            {...a11yProps(index).tab}
          />
        ))}
      </StyledTabs>
      <Box>
        {tabs.map(({ name, content }, index) => (
          <ChkTabPanel
            key={`tab-content-${name}-${index}`}
            value={tabValue}
            index={index}
            {...a11yProps(index).panel}
          >
            {content}
          </ChkTabPanel>
        ))}
      </Box>
    </Box>
  );
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '&.MuiTabs-root': {
    backgroundColor: theme.tokens.segmentedControllerBackground,
    border: theme.borders.borderWidth1,
    borderColor: theme.tokens.segmentedControllerBorder,
    borderRadius: theme.borders.borderRadius2,
    padding: theme.spacing(0),
    minHeight: 'auto',
  },
  '.MuiTabs-flexContainer': {
    width: '100%',
    display: 'inline-flex',
    position: 'relative',
    zIndex: 1,
  },
  '.MuiTabs-indicator': {
    top: theme.spacing(0),
    bottom: theme.spacing(0),
    right: theme.spacing(0),
    height: '100%',
    background: 'none',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      inset: theme.spacing(0),
      // prettier-ignore
      borderRadius: `calc(${theme.borders.borderRadius2} - ${theme.spacing(1)})`,
      backgroundColor: theme.tokens.segmentedControllerActive,
      boxShadow: theme.shadows[2],
    },
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  '&.MuiTab-root': {
    ...theme.typography.body2,
    textTransform: 'none',
    minHeight: 'auto',
    maxWidth: 'none',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.tokens.segmentedControllerText,
    flex: 1,
    '&:hover': {
      opacity: 1,
    },
  },
}));

const ChkTabPanel = (props: ChkTabPanelProps) => {
  const { children, value, index, ...rest } = props;

  if (value !== index) {
    return null;
  }

  return (
    <Box role="tabpanel" {...rest}>
      {children}
    </Box>
  );
};

const a11yProps = (index: number) => {
  return {
    tab: {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    },
    panel: {
      id: `simple-tabpanel-${index}`,
      'aria-labelledby': `simple-tab-${index}`,
    },
  };
};
