function formatDecimal(amount: number, decimal = 2): number {
  return amount / Math.pow(10, decimal);
}

export function formatAmount(
  amount: number,
  currency: string,
  locale = 'en-US',
  fractionDigits = 2
): string {
  if (isNaN(amount) || !currency) {
    return '';
  }

  const value = formatDecimal(amount);

  if (window.Intl && window.Intl.NumberFormat) {
    return new window.Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: fractionDigits,
    }).format(value);
  }

  return `${value.toFixed(fractionDigits)}`;
}
