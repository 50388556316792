import { styled } from '@mui/material';
import InputBase from '@mui/material/InputBase';

import type { ChkInputProps } from './ChkInput.types';

export const ChkInput = ({ ...props }: ChkInputProps) => {
  return (
    <StyledInput
      {...props}
      inputProps={{ className: 'input-element', ...props.inputProps }}
    />
  );
};

const StyledInput = styled(InputBase)(({ theme }) => ({
  '& > .MuiInputBase-input': {
    padding: theme.spacing(0),
  },
  ...theme.typography.body2,
  fontWeight: theme.typography.fontWeightBold,
  height: '4.8rem',
  color: theme.tokens.inputsValueActive,
  border: theme.borders.borderWidth1,
  borderRadius: theme.borders.borderRadius2,
  borderColor: theme.tokens.inputsBorderDefault,
  backgroundColor: theme.tokens.inputsBackgroundDefault,
  padding: [theme.spacing(4), theme.spacing(3)].join(' '),
  '&:hover': {
    borderColor: theme.tokens.inputsBorderHover,
  },
  '&:focus, &:focus-within': {
    borderColor: theme.tokens.inputsBorderActive,
  },
  '& > .MuiInputBase-input::placeholder': {
    color: theme.tokens.inputsValuePlaceholder,
    letterSpacing: '-0.03rem',
    opacity: 1,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.tokens.inputsBackgroundDisabled,
    borderColor: theme.tokens.inputsBackgroundDisabled,
    color: theme.tokens.inputsValuePlaceholder,

    '> .input-element': {
      WebkitTextFillColor: 'unset',
    },
  },
}));
