import type { CSSObject } from '@mui/material';
import { styled } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import { forwardRef } from 'react';

import { CHK_BUTTON_VARIANT } from './ChkButton.constants';
import type { ChkButtonProps, ChkButtonVariant } from './ChkButton.types';

export const ChkButton = forwardRef<HTMLButtonElement | null, ChkButtonProps>(
  ({ children, endIcon, startIcon, ...rest }: ChkButtonProps, ref) => (
    <StyledButton disableTouchRipple {...rest} ref={ref}>
      {startIcon && <StartIconRoot>{startIcon}</StartIconRoot>}
      {children}
      {endIcon && <EndIconRoot>{endIcon}</EndIconRoot>}
    </StyledButton>
  )
);

const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: ChkButtonVariant }>(({ theme, variant }) => {
  const commonLayoutStyles: CSSObject = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(5),
  };
  const commonBorderStyles: CSSObject = {
    border: theme.borders.borderWidth1,
    borderRadius: theme.borders.borderRadius2,
  };
  const commonTypographyStyles: CSSObject = {
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium,
  };
  const commonStyles: CSSObject = {
    cursor: 'pointer',
    transition: `
      background-color 150ms ease-in,
      border-color 150ms ease-in,
      color 150ms ease-in
    `,
  };
  const ContainedOutlinedCommonStyles: CSSObject = {
    ...commonLayoutStyles,
    ...commonBorderStyles,
    ...commonTypographyStyles,
    ...commonStyles,
  };

  switch (variant) {
    case CHK_BUTTON_VARIANT.CONTAINED:
      return {
        ...ContainedOutlinedCommonStyles,
        color: theme.tokens.buttonDefaultTextDefault,
        backgroundColor: theme.tokens.buttonDefaultBackgroundDefault,
        '&:hover': {
          backgroundColor: theme.tokens.buttonDefaultBackgroundHover,
          borderColor: theme.tokens.buttonDefaultBackgroundHover,
        },
        '&:focus': {
          backgroundColor: theme.tokens.buttonDefaultBackgroundPressed,
          borderColor: theme.tokens.buttonDefaultBackgroundPressed,
        },
        '&:disabled': {
          backgroundColor: theme.tokens.buttonDefaultBackgroundDisabled,
          color: theme.tokens.buttonDefaultTextDisabled,
          borderColor: theme.tokens.buttonDefaultBackgroundDisabled,
        },
      };

    case CHK_BUTTON_VARIANT.OUTLINED:
      return {
        ...ContainedOutlinedCommonStyles,
        backgroundColor: theme.tokens.buttonSecondaryBackgroundDefault,
        color: theme.tokens.buttonSecondaryTextDefault,
        '&:hover': {
          backgroundColor: theme.tokens.buttonSecondaryBackgroundHover,
          color: theme.tokens.buttonSecondaryTextDefault,
          borderColor: theme.tokens.buttonSecondaryBorderHover,
        },
        '&:focus': {
          backgroundColor: theme.tokens.buttonSecondaryBackgroundPressed,
          color: theme.tokens.buttonSecondaryTextDefault,
          borderColor: theme.tokens.buttonSecondaryBorderDefault,
        },
        '&:disabled': {
          backgroundColor: theme.tokens.buttonSecondaryBackgroundDisabled,
          color: theme.tokens.buttonSecondaryTextDisabled,
          borderColor: theme.tokens.buttonSecondaryBackgroundDisabled,
        },
      };

    case CHK_BUTTON_VARIANT.TEXT:
      return {
        ...ContainedOutlinedCommonStyles,
        borderColor: 'transparent',
        color: theme.tokens.textButtonDefault,
        '&:hover': {
          color: theme.tokens.textButtonHover,
        },
        '&:focus': {
          color: theme.tokens.textButtonPressed,
        },
        '&:disabled': {
          color: theme.tokens.textButtonDisabled,
        },
      };
  }
});

const StartIconRoot = styled('span')(({ theme }) => ({
  display: 'inherit',
  marginRight: theme.spacing(2),
}));

const EndIconRoot = styled('span')(({ theme }) => ({
  display: 'inherit',
  marginLeft: theme.spacing(3),
}));
