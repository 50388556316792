import { Box, styled } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';

import { ChkButton, ChkHeader } from '@apps/checkout-components';

import { trackEvent } from '@apps/checkout-utils';

import { EducationalIcons } from '../../components/EducationalIcons/EducationalIcons';
import { EducationalList } from '../../components/EducationalList/EducationalList';
import { TermsAndConditions } from '../../components/TermsAndConditions/TermsAndConditions';
import {
  CONTINUE_ON_EDUCATIONAL_SCREEN_BUTTON_TEST_ID,
  UK_EVENTS,
} from '../../global.constants';
import { useScrollToElement } from '../../hooks/useScrollToElement/useScrollToElement';
import { useStatusContext } from '../../hooks/useStatusContext/useStatusContext';
import { CTA_TEXT } from './EducationalView.constants';
import type { EducationalViewProps } from './EducationalView.types';

export const EducationalView = ({ variant }: EducationalViewProps) => {
  const { statusPayload, setStatus } = useStatusContext();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { scrollElementIntoView: scrollButtonIntoView, clearTimeoutRef } =
    useScrollToElement({
      elementRef: buttonRef,
    });

  const ctaClickHandler = useCallback(() => {
    if (!statusPayload) return;

    trackEvent(UK_EVENTS.CONTINUE_ON_THE_WELCOME_SCREEN_CLICK);

    // ROUTING LOGIC
    if (variant === 'EDUCATIONAL') {
      setStatus('BANK_SELECTION');
    } else if (variant === 'PRESELECTED_EDUCATIONAL') {
      setStatus('SUMMARY');
    }
  }, [statusPayload, variant, setStatus]);

  useEffect(() => {
    scrollButtonIntoView();

    return clearTimeoutRef;
  }, [scrollButtonIntoView, clearTimeoutRef]);

  return (
    <EducationalContainer>
      <ChkHeader />
      <TitleWithIconsContainer>
        <EducationalIcons />
        <EducationalTitle>
          Pay instantly from your <br /> bank account
        </EducationalTitle>
      </TitleWithIconsContainer>
      <EducationalList variant={variant} />
      <ButtonContainer>
        <TermsAndConditions />
        <StyledChkButton
          variant="contained"
          onClick={ctaClickHandler}
          data-testid={CONTINUE_ON_EDUCATIONAL_SCREEN_BUTTON_TEST_ID}
          ref={buttonRef}
        >
          {CTA_TEXT}
        </StyledChkButton>
      </ButtonContainer>
    </EducationalContainer>
  );
};

const EducationalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',

  padding: `0 ${theme.spacing(6)} ${theme.spacing(9)} ${theme.spacing(6)}`,

  [theme.breakpoints.up('xs')]: {
    padding: `0 ${theme.spacing(8)} ${theme.spacing(11)} ${theme.spacing(8)}`,
  },
}));

const EducationalTitle = styled('h2')(({ theme }) => ({
  ...theme.typography.h3,
  textAlign: 'center',
  color: theme.tokens.coreMainPrimary,
  fontWeight: theme.typography.fontWeightBold,
  margin: theme.spacing(0),

  [theme.breakpoints.up('xxs')]: {
    ...theme.typography.h2,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const TitleWithIconsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(5),

  [theme.breakpoints.up('xxs')]: {
    gap: theme.spacing(7),
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),

  [theme.breakpoints.up('xs')]: {
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
}));

const StyledChkButton = styled(ChkButton)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
  fontWeight: 500,
}));
