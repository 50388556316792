export const sentrySanitizeUrl = (url: string | undefined) => {
  return String(url).replace(/https?:\/\//, '');
};

export const isValidObject = (
  val: unknown
): val is Record<string | number, unknown> => {
  return (
    val !== null &&
    typeof val === 'object' &&
    !Array.isArray(val) &&
    Object.keys(val).length > 0
  );
};
