import { Box, styled } from '@mui/material';
import { useEffect, useRef } from 'react';

import { SummaryBankCard } from '../../components/SummaryBankCard/SummaryBankCard';
import { SummaryDetails } from '../../components/SummaryDetails/SummaryDetails';
import { SummaryDrawer } from '../../components/SummaryDrawer/SummaryDrawer';
import { SummaryHeader } from '../../components/SummaryHeader/SummaryHeader';
import { useScrollToElement } from '../../hooks/useScrollToElement/useScrollToElement';
import { useStatusPolling } from '../../hooks/useStatusPolling/useStatusPolling';
import type { SummaryViewProps } from './SummaryView.constants';

export const SummaryView = ({ isPollingOn }: SummaryViewProps) => {
  const { startStatusPolling, stopStatusPolling, statusPayload } =
    useStatusPolling();
  const drawerRef = useRef<HTMLDivElement>(null);
  const { scrollElementIntoView: scrollDrawerIntoView, clearTimeoutRef } =
    useScrollToElement({ elementRef: drawerRef });

  const { details } = statusPayload || {};
  const { redirectUrl } = details || {};

  useEffect(() => {
    const shouldStartPolling = !redirectUrl || isPollingOn;

    if (shouldStartPolling) {
      startStatusPolling();
    } else {
      // stopStatusPolling();
      scrollDrawerIntoView();
    }
    return () => {
      stopStatusPolling();
      clearTimeoutRef();
    };
  }, [
    stopStatusPolling,
    startStatusPolling,
    redirectUrl,
    clearTimeoutRef,
    scrollDrawerIntoView,
    statusPayload?.status,
    isPollingOn,
  ]);

  useEffect(() => {
    scrollDrawerIntoView();

    return clearTimeoutRef;
  }, [scrollDrawerIntoView, clearTimeoutRef]);

  return (
    <SummaryContainer>
      <SummaryHeader />
      <SummaryBankCard />
      <SummaryDetails />
      <SummaryDrawer ref={drawerRef} />
    </SummaryContainer>
  );
};

const SummaryContainer = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(5)}`,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.up('xs')]: {
    padding: `${theme.spacing(7)} ${theme.spacing(8)} 0`,
  },
}));
