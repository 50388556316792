import { Box, Link, styled, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';

import { ChkStatusScreen } from '@apps/checkout-components';

import { trackEvent } from '@apps/checkout-utils';

import { UK_EVENTS } from '../../global.constants';
import { useStatusContext } from '../../hooks/useStatusContext/useStatusContext';
import { useStatusPolling } from '../../hooks/useStatusPolling/useStatusPolling';
import { BANK_REDIRECT_MOBILE } from './BankRedirectMobileView.constants';

export const BankRedirectMobileView = () => {
  const { startStatusPolling, stopStatusPolling } = useStatusPolling();
  const { setStatus } = useStatusContext();

  useEffect(() => {
    startStatusPolling();

    return stopStatusPolling;
  }, [startStatusPolling, stopStatusPolling]);

  const handleBackClick = useCallback(() => {
    stopStatusPolling();
    trackEvent(UK_EVENTS.GO_BACK_FROM_MOBILE_TO_SUMMARY);
    setStatus('SUMMARY');
  }, [setStatus, stopStatusPolling]);

  return (
    <>
      <ChkStatusScreen
        title={BANK_REDIRECT_MOBILE.TITLE}
        text={BANK_REDIRECT_MOBILE.TEXT}
        animation="mobileLoading"
        sx={{ height: '100%' }}
      />
      <StyledLinkWrapper>
        <Typography variant="body2">
          {BANK_REDIRECT_MOBILE.LINK_TEXT}{' '}
          <StyledLink onClick={handleBackClick}>
            {BANK_REDIRECT_MOBILE.BACK_LINK_TEXT}
          </StyledLink>
        </Typography>
      </StyledLinkWrapper>
    </>
  );
};

const StyledLinkWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: theme.tokens.coreMainSecondary,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  ':hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  textDecoration: 'none',
  fontWeight: 500,
  color: theme.tokens.coreMainPrimary,
}));
