import { ChkStatusScreen } from '../';
import type { ChkGenericLoaderProps } from './ChkGenericLoader.types';

export const ChkGenericLoader = ({
  children,
  ...restProps
}: ChkGenericLoaderProps) => {
  return (
    <ChkStatusScreen animation="loading" loop {...restProps}>
      {children}
    </ChkStatusScreen>
  );
};
