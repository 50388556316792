import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { API_ENDPOINTS, useApi } from '@apps/checkout-utils';

import type {
  BanksContextData,
  BanksContextProviderProps,
} from './banks.types';

/**
 * This factory returns `BanksContextProvider`, `useBanksContext` and `withBanksContextProvider`;
 * Usage:
 * ````
 *  import { banksContextFactory } from '@apps/checkout-contexts';
 *  const { BanksContextProvider, useBanksContext, withBanksContextProvider } = banksContextFactory<BanksEndpointPayloadType>();
 *
 *  export { BanksContextProvider, useBanksContext, withBanksContextProvider };
 * ````
 */
export const banksContextFactory = <T,>() => {
  const BanksContext = createContext<BanksContextData<T> | null>(null);

  const useBanksContext = () => {
    const context = useContext(BanksContext);

    if (!context) {
      throw new Error(
        'useBanksContext must be used within a <BanksContextProvider>'
      );
    }

    return context;
  };

  const BanksContextProvider = ({
    children,
    country,
    currency,
    customerId,
  }: BanksContextProviderProps) => {
    const [banksPayload, setBanksPayload] =
      useState<BanksContextData<T>['banksPayload']>(null);

    const GET_BANKS_ENDPOINT = useMemo(
      () => API_ENDPOINTS.getBanks(customerId, country, currency),
      [customerId, country, currency]
    );

    const { isLoading, executeRequest, error } =
      useApi<BanksContextData<T>['banksPayload']>(GET_BANKS_ENDPOINT);

    const requestForBanksList = useCallback(
      async (options?: RequestInit) => {
        executeRequest(options)
          .then((res) => {
            if (res) {
              setBanksPayload(res);
            }
          })
          .catch((e) => console.error(e));
      },
      [executeRequest]
    );

    return (
      <BanksContext.Provider
        value={{
          banksPayload,
          isLoading,
          error,
          requestForBanksList,
        }}
      >
        {children}
      </BanksContext.Provider>
    );
  };

  /**
   * helper to be used when wrapping a component with BankContextProvider is needed, out of JSX structures eg. in functions
   */
  const withBanksContextProvider = ({
    children,
    ...props
  }: BanksContextProviderProps) => (
    <BanksContextProvider {...props}>{children}</BanksContextProvider>
  );

  return { useBanksContext, BanksContextProvider, withBanksContextProvider };
};
