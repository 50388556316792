import { useCallback, useRef } from 'react';
import type { RefObject } from 'react';

import { SCROLL_INTO_VIEW_TIMEOUT } from '../../global.constants';
import type { useScrollToElementProps } from './useScrollToElement.types';

export const useScrollToElement = <T extends RefObject<Element>>({
  elementRef,
  timeout = SCROLL_INTO_VIEW_TIMEOUT,
}: useScrollToElementProps<T>) => {
  const timeoutIdRef = useRef<NodeJS.Timer | null>(null);

  const scrollElementIntoView = useCallback(
    () =>
      (timeoutIdRef.current = setTimeout(
        () =>
          elementRef.current?.scrollIntoView({
            behavior: 'smooth',
          }),
        timeout
      )),
    [elementRef, timeout]
  );

  const clearTimeoutRef = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  }, []);

  return { scrollElementIntoView, clearTimeoutRef };
};
