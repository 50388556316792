import { Box, ButtonBase, styled, Typography } from '@mui/material';

import type { ChkMethodButtonProps } from './ChkMethodButton.types';

export const ChkMethodButton = ({
  title,
  subtitle,
  icon,
  disabled,
  isSelected,
  ...rest
}: ChkMethodButtonProps) => (
  <Button disableRipple isSelected={isSelected} disabled={disabled} {...rest}>
    <TextWrapper>
      <Title>{title}</Title>
      {Boolean(subtitle) && <Description>{subtitle}</Description>}
    </TextWrapper>

    {Boolean(icon) && icon}
  </Button>
);

const Button = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean | undefined }>(({ theme, disabled, isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
  color: theme.tokens.cardsOptionsTextTitle,
  border: theme.borders.borderWidth1,
  borderColor: theme.tokens.cardsOptionsBorderDefault,
  borderRadius: theme.borders.borderRadius2,
  backgroundColor: theme.tokens.coreBackgroundsSecondary,
  gap: theme.spacing(5),
  width: '100%',
  opacity: disabled ? '0.4' : '1',
  transition: `
  background-color 150ms ease-in,
  border-color 150ms ease-in
`,
  '&:hover': {
    borderColor: theme.tokens.cardsOptionsBorderHover,
  },
  '&:focus': {
    borderColor: theme.tokens.cardsOptionsBorderActive,
  },
  ...(isSelected
    ? {
        borderColor: theme.tokens.cardsOptionsBorderActive,
        '&:hover, &:focus, &:disabled': {
          borderColor: theme.tokens.cardsOptionsBorderActive,
        },
      }
    : {}),
  '&:disabled': {
    borderColor: theme.tokens.cardsOptionsBorderDefault,
  },
}));

const TextWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'flex-start',
  textAlign: 'left',
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.tokens.cardsOptionsTextTitle,
}));

const Description = styled(Typography)(({ theme }) => ({
  ...theme.typography.body3,
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.tokens.cardsOptionsTextSubtitle,
}));
