import {
  Box,
  Container,
  GlobalStyles,
  styled,
  Typography,
} from '@mui/material';

import { ChkIconVoltLogo } from '../icons/ChkIconVoltLogo';
import type { ChkPageWrapperProps } from './ChkPageWrapper.types';

export const ChkPageWrapper = ({ children, ...rest }: ChkPageWrapperProps) => (
  <ViewportContainer>
    <GlobalStyles
      styles={(theme) => ({
        body: {
          background: theme.tokens.coreBackgroundsPrimaryAlt,
        },
      })}
    />
    <PageContainer {...rest} maxWidth="xs" disableGutters>
      {children}
    </PageContainer>

    <FooterContainer>
      <StyledTypography variant="body2">Powered by</StyledTypography>

      <ChkIconVoltLogo />
    </FooterContainer>
    <DemoInfo>Demo experience</DemoInfo>
  </ViewportContainer>
);

const ViewportContainer = styled(Box)(({ theme }) => ({
  minHeight: ['100vh', '100dvh'],
  background: theme.tokens.coreBackgroundsPrimaryAlt,
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: `calc(100vw - 100%)`, // vw is not affected by scrollbars, so this prevents layout shifts when the scrollbar is shown; ref on #3: https://dev.to/rashidshamloo/preventing-the-layout-shift-caused-by-scrollbars-2flp
  [theme.breakpoints.up('xs')]: {
    paddingTop: theme.spacing(8),
  },
  paddingBottom: theme.spacing(6),
}));

const DemoInfo = styled(Typography)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  padding: theme.spacing(1),
  textAlign: 'center',
  textTransform: 'uppercase',
  fontSize: '12px',
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: '1.2px',
  background: '#6ae69e',
  pointerEvents: 'none',
  zIndex: 1000,
}));

const PageContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  background: 'white',
  display: 'flex',
  minHeight: ['100vh', '100dvh'],
  [theme.breakpoints.up('xs')]: {
    minHeight: '700px',
    borderRadius: theme.borders.borderRadius2,
    boxShadow: '0 4px 80px 0 rgba(31, 48, 60, 0.05)',
  },
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${theme.spacing(1)} 0`,

  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    padding: `${theme.spacing(5)} 0`,
  },

  '& > svg': {
    color: theme.tokens.coreMainPrimary,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: theme.tokens.coreMainPrimary,
  opacity: '0.5',
}));
