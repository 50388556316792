import { styled } from '@mui/material';
import List from '@mui/material/List';

import {
  ChkIconCheckCircle,
  ChkIconFingerprint,
  ChkIconNewShield,
  ChkListItem,
} from '@apps/checkout-components';

import { STATUSES } from './EducationalList.constants';
import type {
  EducationalListItem,
  EducationalListProps,
} from './EducationalList.types';

const EDUCATIONAL_LIST_DEFAULT_DATA: EducationalListItem[] = [
  {
    icon: <ChkIconNewShield viewBox="0 0 24 25" />,
    primaryText: 'Select your bank',
    secondaryText: 'From the list provided',
  },
  {
    icon: <ChkIconFingerprint viewBox="0 0 24 25" />,
    primaryText: 'Log in to your bank',
    secondaryText: 'Securely, in seconds',
  },
  {
    icon: <ChkIconCheckCircle viewBox="0 0 24 25" />,
    primaryText: 'Approve your payment',
    secondaryText: 'A single tap is all it takes',
  },
];

const EDUCATIONAL_LIST_PRESELECTED_DATA: EducationalListItem[] = [
  {
    icon: <ChkIconNewShield viewBox="0 0 24 25" />,
    primaryText: 'Confirm the transaction',
    secondaryText: 'Review your purchase',
  },
  ...EDUCATIONAL_LIST_DEFAULT_DATA.slice(1),
];

const getEducationalListData = (variant: string): EducationalListItem[] => {
  return variant === STATUSES.EDUCATIONAL
    ? EDUCATIONAL_LIST_DEFAULT_DATA
    : EDUCATIONAL_LIST_PRESELECTED_DATA;
};

export const EducationalList = ({ variant }: EducationalListProps) => {
  const educationalListData: EducationalListItem[] =
    getEducationalListData(variant);

  return (
    <StyledList>
      {educationalListData.map(
        ({ icon, primaryText, secondaryText }, index) => (
          <StyledChkListItem
            key={`onboarding-point-${index}`}
            icon={icon}
            primaryText={primaryText}
            secondaryText={secondaryText}
          />
        )
      )}
    </StyledList>
  );
};

const StyledList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
  padding: `${theme.spacing(8)} ${theme.spacing(5)}`,

  '& .MuiListItem-root': {
    marginBottom: 0,
  },

  [theme.breakpoints.up('xxs')]: {
    gap: theme.spacing(5),
  },
}));

const StyledChkListItem = styled(ChkListItem)(({ theme }) => ({
  width: '24.8rem',

  '& .MuiListItemText-root': {
    marginLeft: theme.spacing(3),
  },

  '& .MuiTypography-body1': {
    ...theme.typography.body2,
    fontWeight: 500,
  },

  '& .MuiTypography-body2': {
    ...theme.typography.body3,
  },

  [theme.breakpoints.up('xxs')]: {
    width: '30.2rem',

    '& .MuiTypography-body1': {
      ...theme.typography.body1,
      fontWeight: 500,
    },

    '& .MuiTypography-body2': {
      ...theme.typography.body2,
    },
  },
}));
