import { Box, Link, styled, Typography } from '@mui/material';

import {
  CONTINUE_AT_BROWSER_LINK_TEST_ID,
  CONTINUE_AT_BROWSER_TEXT_TEST_ID,
} from '../../../../global.constants';
import { DRAWER_CONTINUE_ON_DESKTOP } from './DrawerContinueOnDesktop.constants';
import type { DrawerContinueOnDesktopProps } from './DrawerContinueOnDesktop.types';

export const DrawerContinueOnDesktop = ({
  redirectUrl,
}: DrawerContinueOnDesktopProps) => {
  return (
    <StyledBox>
      <StyledTypography
        variant="body2"
        data-testid={CONTINUE_AT_BROWSER_TEXT_TEST_ID}
      >
        {DRAWER_CONTINUE_ON_DESKTOP.noBankingApp}{' '}
        <StyledLink
          href={redirectUrl}
          data-testid={CONTINUE_AT_BROWSER_LINK_TEST_ID}
        >
          {DRAWER_CONTINUE_ON_DESKTOP.continueOnYourBrowser}
        </StyledLink>
      </StyledTypography>
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.tokens.bottomSheetHandoffBackgroundHighlight,
  textAlign: 'center',
  padding: theme.spacing(4),
  borderTop: theme.borders.borderWidth1,
  borderTopColor: theme.tokens.coreBorderTertiaryLight,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.tokens.bottomSheetTextCardTitle,
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: '-0.3px',
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.tokens.bottomSheetBodyLink,
  textDecoration: 'none',

  '&:hover, &:visited:hover': {
    color: theme.tokens.coreMainLinkHover,
  },

  '&:visited': {
    color: theme.tokens.coreMainLinkPressed,
  },
}));
