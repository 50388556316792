import { styled } from '@mui/material';
import ListItem from '@mui/material/ListItem';

import { ChkListIcon } from './ChkListIcon';
import type { ChkListItemProps } from './ChkListItem.types';
import { ChkListText } from './ChkListText';

export const ChkListItem = ({
  icon,
  primaryText,
  secondaryText,
  ...rest
}: ChkListItemProps) => {
  return (
    <StyledListItem alignItems="flex-start" {...rest}>
      <ChkListIcon>{icon}</ChkListIcon>
      <ChkListText primaryText={primaryText} secondaryText={secondaryText} />
    </StyledListItem>
  );
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginTop: 0,
  marginBottom: theme.spacing(6),
  padding: 0,

  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(7),
  },
}));
