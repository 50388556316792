import { useCallback } from 'react';

import {
  captureSchemaValidationError,
  COMMON_HEADERS_KEYS,
} from '@apps/checkout-utils';

import { X_VOLT_API_VERSION } from '../../global.constants';
import { UKStatusPayloadSchema } from '../../global.schema';
import { useStatusContext } from '../useStatusContext/useStatusContext';
import type { NewUkStatusPayload } from './useStatusPolling.types';

export const useStatusPolling = () => {
  const {
    startPolling,
    stopPolling,
    setStatusPayload,
    statusPayload: currentStatusPayload,
  } = useStatusContext();

  const updateStatusDetails = useCallback(
    (newStatusPayload: NewUkStatusPayload) => {
      const parsedResponse = UKStatusPayloadSchema.safeParse(newStatusPayload);

      if (!parsedResponse.success) {
        captureSchemaValidationError(parsedResponse.error, {
          contexts: {
            response: {
              statusResponse: newStatusPayload,
            },
            error: {
              schemaError: parsedResponse.error.issues,
            },
          },
        });
      }

      if (!currentStatusPayload?.status) return;
      if (newStatusPayload?.details.redirectUrl) {
        const newStatus =
          newStatusPayload.status === 'BANK_REDIRECT_MOBILE'
            ? 'BANK_REDIRECT_MOBILE'
            : currentStatusPayload?.status;

        if ((newStatusPayload?.status as string) === 'APPROVED') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          window.location.href = (newStatusPayload as any)?.details
            .redirectUrl as string;
          return;
        }
      

        setStatusPayload({
          ...newStatusPayload,
          status: newStatus,
        });
      }
    },
    [setStatusPayload, currentStatusPayload]
  );

  const startStatusPolling = useCallback(() => {
    startPolling({
      requestOptions: {
        headers: {
          [COMMON_HEADERS_KEYS.X_VOLT_API_VERSION]: X_VOLT_API_VERSION,
        },
      },
      handleResponse: updateStatusDetails,
    });
  }, [startPolling, updateStatusDetails]);

  return {
    startStatusPolling,
    statusPayload: currentStatusPayload,
    stopStatusPolling: stopPolling,
  };
};
