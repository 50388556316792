import { Backdrop, Drawer, styled } from '@mui/material';
import { forwardRef } from 'react';

import type { ChkDrawerProps } from './ChkDrawer.types';

export const ChkDrawer = forwardRef<HTMLDivElement, ChkDrawerProps>(
  ({ BackdropProps, children, hideBackdrop, open, ...rest }, ref) => (
    <>
      {!hideBackdrop && (
        <Backdrop
          {...BackdropProps}
          open={Boolean(open)}
          sx={{
            ...BackdropProps?.sx,
            position: 'absolute',
          }}
        />
      )}

      <StyledDrawer
        ref={ref}
        {...rest}
        anchor="bottom"
        elevation={0}
        open={open}
        variant="persistent"
      >
        {children}
      </StyledDrawer>
    </>
  )
);

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',

  '.MuiDrawer-paper': {
    position: 'static',
    background: theme.tokens.bottomSheetHandoffBackground,
    borderRadius: `${theme.borders.borderRadius2} ${theme.borders.borderRadius2} 0 0`,
    border: 'none',
  },
}));
