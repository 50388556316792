import { Box, styled, Typography } from '@mui/material';

import { ChkButton, theme } from '@apps/checkout-components';

import { useBanksContext } from '../../../hooks/useBanksContext/useBanksContext';
import {
  ERROR_SCREEN_CTA_TEXT,
  ERROR_SCREEN_SUBTEXT,
  ERROR_SCREEN_TEXT,
} from './ErrorScreen.constants';

export const ErrorScreen = () => {
  const { requestForBanksList } = useBanksContext();

  return (
    <ErrorScreenBox>
      <ErrorIcon />

      <Typography
        sx={{ marginBottom: theme.spacing(3) }}
        variant="h2"
        fontWeight={theme.typography.fontWeightBold}
        color={theme.tokens.coreMainPrimary}
      >
        {ERROR_SCREEN_TEXT}
      </Typography>

      <Typography
        color={theme.tokens.coreMainSecondary}
        variant="body2"
        fontWeight={theme.typography.fontWeightRegular}
      >
        {ERROR_SCREEN_SUBTEXT}
      </Typography>

      <Box sx={{ padding: theme.spacing(6), maxWidth: '31rem', width: '100%' }}>
        <ChkButton
          sx={{ width: '100%' }}
          variant="outlined"
          onClick={() => requestForBanksList()}
        >
          {ERROR_SCREEN_CTA_TEXT}
        </ChkButton>
      </Box>
    </ErrorScreenBox>
  );
};

// TODO: Change to Lottie animation when will be ready
const ErrorIcon = () => (
  <ErrorIconBox>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="116"
      height="116"
      viewBox="0 0 116 116"
      fill="none"
      role="img"
    >
      <path
        d="M57.9996 2.34961C88.7128 2.34961 113.65 27.2864 113.65 57.9996C113.65 88.7128 88.7128 113.65 57.9996 113.65C27.2864 113.65 2.34961 88.7128 2.34961 57.9996C2.34961 27.2864 27.2864 2.34961 57.9996 2.34961Z"
        stroke="#FF6D6D"
        strokeWidth="3.6"
      />
      <path d="M42.5 40.75L74 72.75" stroke="#FF6D6D" strokeWidth="3.6" />
      <path d="M74.125 40.75L42.625 72.5" stroke="#FF6D6D" strokeWidth="3.6" />
    </svg>
  </ErrorIconBox>
);

const ErrorScreenBox = styled(Box)({
  marginTop: '9.9rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

const ErrorIconBox = styled(Box)({
  padding: '3.4rem',
});
