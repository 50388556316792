import { Box, styled } from '@mui/material';
import { useMemo } from 'react';

import { ChkLoadingBar } from '@apps/checkout-components';

import { useDetection } from '@apps/checkout-utils';

import { useStatusContext } from '../../../hooks/useStatusContext/useStatusContext';
import { LOADING_BAR_LABELS } from './DrawerContent.constants';
import { DrawerDesktopView } from './DrawerDesktopView/DrawerDesktopView';
import { DrawerMobileView } from './DrawerMobileView/DrawerMobileView';

export const DrawerContent = () => {
  const { statusPayload } = useStatusContext();
  const { isMobile } = useDetection();

  const isIPad =
    navigator.userAgent.match(/(iPad)/) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  const { details } = statusPayload || {};
  const { redirectUrl, bank } = details || {};
  const { name = '', assets } = bank || {};
  const { icon = '' } = assets || {};

  const drawerContent = useMemo(
    () =>
      isMobile && !isIPad ? (
        <DrawerMobileView
          redirectUrl={redirectUrl || ''}
          bankName={name}
          iconUrl={icon}
        />
      ) : (
        <DrawerDesktopView redirectUrl={redirectUrl || ''} iconUrl={icon} />
      ),
    [isMobile, redirectUrl, icon, name]
  );

  if (!redirectUrl) {
    return (
      <StyledBox>
        <ChkLoadingBar
          leftText={LOADING_BAR_LABELS.WAIT}
          rightText={LOADING_BAR_LABELS.CONNECTING}
        />
      </StyledBox>
    );
  }

  return drawerContent;
};

const StyledBox = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(8)}`,
}));
