import type { ChkSummaryProps } from '@apps/checkout-components';

import { formatAmount } from '@apps/checkout-utils';

import type { UkSummaryDetails } from '../../global.types';
import { LAST_ROW_LABEL } from './SummaryDetails.constants';

export const formatSummaryDetails = (
  summaryDetails: UkSummaryDetails | undefined
) => {
  if (!Array.isArray(summaryDetails)) return [];

  return summaryDetails.map(({ k, v }) => ({
    label: k,
    value: v,
  })) satisfies ChkSummaryProps['summaryItems'];
};

export const getTotalAmount = (amount: number | undefined) => {
  if (typeof amount !== 'number') return [];

  return [
    {
      label: LAST_ROW_LABEL,
      value: formatAmount(amount, 'GBP'),
    },
  ] satisfies ChkSummaryProps['summaryItems'];
};
