export const X_VOLT_API_VERSION = '4';

export const UK_EVENTS = {
  BANK_CHANGE_CLICK: 'Bank change click',
  BANK_SEARCHED: 'Bank name searched',
  BANK_SELECT: 'Bank select',
  CHECKOUT_IS_LOADING: 'Checkout is loading',
  CONTINUE_ON_THE_WELCOME_SCREEN_CLICK: 'Continue on the Welcome screen click',
  GOOGLE_LANGUAGE_CHANGE: 'Google translate used on checkout',
  PAGE_VISIT_OPEN_BANKING: 'Open banking page visit',
  QR_CODE_DISPLAYED: 'QR code displayed',
  TERMS_AND_CONDITIONS_CLICK: 'Terms and conditions click',
  GO_BACK_FROM_MOBILE_TO_SUMMARY:
    'Go back from Continue on mobile to Summary view click',
} as const;

export const CHECKOUT_VERSION = '3.0';
export const INTEGRATION_TYPE = 'hosted';
export const UK_COUNTRY_ID = 'GB';
export const UK_CURRENCY_CODE = 'GBP';
export const LANGUAGE = 'en';

export const POWER_GRID_PROVIDER_NAME = 'PowerGrid';

export const CLOSE_ICON_TEST_ID = 'close-icon';
export const CONTINUE_AT_BROWSER_TEXT_TEST_ID = 'continue-at-browser-text';
export const CONTINUE_AT_BROWSER_LINK_TEST_ID = 'continue-at-browser-link';
export const CONTINUE_ON_EDUCATIONAL_SCREEN_BUTTON_TEST_ID =
  'continue-on-educational-screen-button';
export const INFO_ICON_TEST_ID = 'info-icon';
export const QR_TEXT_TEST_ID = 'qr-text';
export const QR_TITLE_TEST_ID = 'qr-title';
export const QR_CODE_TEST_ID = 'qr-code';
export const QR_CODE_ICON_TEST_ID = 'qr-code-icon';
export const PAY_WITH_BUTTON_TEST_ID = 'pay-with-button';
export const SEARCH_BANK_INPUT_TEST_ID = 'search-bank-input';
export const TERMS_AND_CONDITIONS_TEST_ID = 'terms-and-conditions';
export const DRAWER_DESKTOP_VIEW_TEST_ID = 'drawer-desktop-view';
export const DRAWER_MOBILE_VIEW_TEST_ID = 'drawer-mobile-view';

export const SCROLL_INTO_VIEW_TIMEOUT = 1000; // ms

export const STATUSES = {
  EDUCATIONAL: 'EDUCATIONAL',
  PRESELECTED_EDUCATIONAL: 'PRESELECTED_EDUCATIONAL',
  BANK_REDIRECT: 'BANK_REDIRECT',
  BANK_REDIRECT_MOBILE: 'BANK_REDIRECT_MOBILE',
  BANK_SELECTION: 'BANK_SELECTION',
  SUMMARY: 'SUMMARY',
  UNKNOWN: 'UNKNOWN',
} as const;
